import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import axios from "axios";

function SearchWeb() {
  const [inputValue, setInputValue] = useState({});

  const [records, SetRecords] = useState()
  const [totalRecord, SetTotalRecord] = useState();
  const [alertError, setAlertError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [waiting, setWaiting] = useState(false);

  // STORE INPUT VALUE
  const inputEvent = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputValue(values => ({ ...values, [name]: value }));
  }

  // SUBMIT FORM
  const onSubmit = (event) => {
    event.preventDefault();
    setWaiting(true)
    axios.post(`${process.env.REACT_APP_API_URL}search-web.php`, inputValue)
      .then(function (responseData) {
        const respons = responseData.data;
        if (respons.status === true && respons.record > 0) {
          setWaiting(false);
          setAlertSuccess(true);
          SetTotalRecord(respons.record)
          SetRecords(respons.data)
        } else if (respons.status === true && respons.record === 0) {
          setWaiting(false);
          setAlertSuccess(true);
          SetTotalRecord(respons.record)
        } else {
          setAlertError(true);
        }
      }
      );
  }

  useEffect(() => {
  }, [waiting]);

  return (
    <React.Fragment>
      <Helmet> <title>Search</title> </Helmet>
      <div className="sectionWrap">
        <div className="sectionContainer">
          <div className="searchTemplate">
            {/* SEARCH FORM */}
            <form onSubmit={onSubmit} autoComplete="off">
              <div className='dataForm'>
                
                <div className="data_row">
                  <div className="label"><i className="fa fa-search" aria-hidden="true"></i> Search Content Here</div>
                  <div className="inputField">
                    <input type="text" name="searchInput" onChange={inputEvent} placeholder="Ex: Cannabis, Medical, Patient etc." required />
                  </div>
                  <div className="inputField button">
                    <input type="submit" value="Search" />
                  </div>
                </div>
              </div>
            </form>
            {/* SEARCH RESULT */}
            <div className="searchResult">
              {(waiting === true && alertError === false && alertSuccess === false) ?
                <div className='loadingContainer'><img src={require('../assets/images/dots.gif')} alt="" /></div>
                : (waiting === false && alertSuccess === true && totalRecord > 0) ?
                  <React.Fragment>
                    <div className="fetchData">
                      <div className='countRecord'>Total {totalRecord} Record Found </div>
                      {records.map((data) => {
                        return (
                          <React.Fragment key={data.id}>
                            <div className="fetchRows">
                              <h6>{data.title}</h6>
                              <p>{data.description}</p>
                            </div>
                          </React.Fragment>
                        )
                      })}
                    </div>
                  </React.Fragment>
                  : (waiting === false && alertSuccess === true && totalRecord === 0) ?
                  <div className='error'>No records found. Please try a new search.</div>
                  : (waiting === false && alertError === true) &&
                  <div>Error: Try Again!!!</div>
                }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SearchWeb