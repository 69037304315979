import React from 'react'

const SuccessEnrollment = () => {
 return (
  <div className="sectionWrap" style={{ marginTop: '10px' }}>
   <div
    className="sectionContainer"
    style={{
     display: 'flex',
     flexWrap: 'wrap',
     flexDirection: 'column',
     columnGap: '10px',
    }}
   >
    <div>
     <h2>Welcome to Bennabis Health!</h2>

     <p style={{ width: '100%', maxWidth: '500px' }}>
      You have successfully created your account. To complete your registration,
      we must verify your email. Please check your email for a note from
      Bennabis Health. Follow the instructions in the email to complete the
      verification process.
     </p>
     <p style={{ width: '100%', maxWidth: '500px' }}>
      If you do not receive an email from Bennabis Health, be sure to check your
      Junk or Spam. If you need assistance, please contact Bennabis Health
      Member Services at{' '}
      <a href="mailto:team@bennabis.com.">team@bennabis.com.</a>
     </p>
    </div>
   </div>
  </div>
 )
}
export default SuccessEnrollment
