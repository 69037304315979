const accordionData = [
  {
    title: 'What is medical cannabis?',
    content: `<div class="content">
    <p>Medical cannabis is a medicine that includes a variety of products derived from the Cannabis sativa plant. People have turned to cannabis as an herbal remedy for centuries. It is used to ease symptoms of conditions or side-effects of certain treatments.</p>
    <p>Most states have medical cannabis programs designed to legally allow patients access to medical cannabis. In states with medical cannabis programs, healthcare providers can recommend medical cannabis when the patient is qualified.</p>
    <p>There are many different options and varieties of medical cannabis to choose from depending on the type of plant or strain you choose and how you choose to ingest it.  Medical cannabis products are designed to be effective, safe, and easily consumable for medical cannabis patients.</p>
    <p>With a Bennabis Health membership you gain access to a community of experts to provide guidance and support you on your medical cannabis journey.</p>
  </div>`
  },
  {
    title: 'What is the history of medical cannabis?',
    content: `<div class="content">
    <p>The history of cannabis spans from ancient Chinese medicine and civilizations, all the way up to use in modern day health care.</p>
    <p>The Chinese used it for the purposes of herbal healing and was recommended for more than 100 ailments. In Egypt it was used for glaucoma, enemas, and inflammation. In India it was used as an anesthetic. In other areas it was used to treat pain and even during surgeries. In Europe it was highly sought after for use in tea to relieve menstrual cramps and a variety of other symptoms.</p>
    <p>Through the years accessibility has been granted and rescinded at different times. Many necessary changes have been enacted through compassionate care use acts, and various levels of acceptance at the state and federal levels. </p>
    <p>Federal prohibition has made the ability to properly study the health benefits of cannabis challenging. As more studies are being conducted, we are learning more about the vast potential of this plant.</p>
  </div>`
  },
  {
    title: 'How do I talk to my doctor if I am interested in starting medical cannabis?',
    content: `<div class="content">
    <p>We understand that starting the conversation can be difficult. Bennabis Health is here to help your arm yourself with information to break the ice and feel confident having the discussion.</p>
    <ul>
      <li>Medical cannabis is medicine. If you are interested in medical cannabis and all its natural benefits take some time to learn about it.</li>
      <li>Review our state lists to find out whether you have a qualifying condition.</li>
      <li>Be prepared to explain why you are interested in trying medical cannabis. </li>
      <li>Keep a journal to document side effects, outcomes, or improvements you may or may not be experiencing with your current traditional medicinal regimen. Become a member today to use the Bennabis Health patient journal and health tracker.</li>
      <li>Having good data points can simplify the conversation and allow you to get straight to the point.</li>
      <li>Just like any other topic discussed with your doctor, be sure to understand the benefits and risks.</li>
      <li>Share success stories from other patients with your condition or symptoms.</li>
    </ul>
    <p>Providers aren’t always formally trained in medical cannabis. Advocating for yourself with a well thought out care plan will help your provider feel comfortable with your decision.  </p>
    <p>Bennabis Health is actively working to educate providers on the benefits and risks of medical cannabis with the goal of helping to make your medical cannabis journey easier. We provide you with resources and a community of experts who can assist you in specific details you may want to consider for your condition so you can best initiate the conversation. We are here to help you take the first step.</p>
  </div>`
  },

  {
    title: 'How do I get a medical cannabis card?',
    content: `<div class="content">
    <p>Generally, you need to have a qualifying condition and must obtain a written recommendation from a licensed healthcare provider to obtain a medical cannabis card.  Each state has their own laws as to what type of providers can recommend medical cannabis. Every state has its own list of qualifying conditions and instructions on how to apply for a medical cannabis license once you have the recommendation. </p>
    <p>You should speak with your physician about medical cannabis. If your doctor has questions, refer them to Bennabis Health and we will help educate them through our physician outreach program. If your doctor is not licensed to recommend medical cannabis, Bennabis Health provides access to <a href="/affiliates">online resources</a> which can assist you in getting your medical cannabis card.</p>
  </div>`
  },
  {
    title: 'What does a cannabis recommendation look like and what do I do with it?',
    content: `<div class="content">
    <p>A medical cannabis recommendation is made by a healthcare provider and legally allows you to use, purchase, possess and, in some states, cultivate cannabis medicine once your state’s other requirements are met. Healthcare providers can legally recommend using this medication just like any other medication. The major difference being that medical cannabis is not a prescription for a specific type of cannabis medicine. </p>
    <p>Instead, this is a recommendation from your healthcare provider that you have a qualifying condition and can benefit from the use of medical cannabis. Your provider makes this recommendation considering that the benefits outweigh any risks. </p>
    <p>Once your healthcare provider signs your medical cannabis recommendation, there is an application process to be completed before you can be legally allowed to participate in your state’s program. After you are approved through the application process you receive your medical cannabis card. This process is administered by state agencies and health departments. </p>
    <p>Once you receive your card, you may shop at dispensaries to purchase your cannabis medicine. Each dispensary may have different products so take some time to speak with the budtender and visit more than one dispensary in your cannabis journey. </p>
    <p>See our question on <strong><a href="#bookmark01">What is the law on medical cannabis?</a> <strong> for the direct link to your state’s website to find the guidelines and laws in your state. You should also print any necessary paperwork to bring with you to your healthcare provider. </p>
  </div>`
  },
  {
    title: 'What conditions is medical cannabis used for?',
    content: `<div class="content">
    <p>Cannabis medicine can treat the symptoms of a variety of conditions as well as side effects of certain treatments and medications. The allowable conditions and symptoms vary based on individual state laws.  These conditions and symptoms include, but are not limited to:</p>
    <ul>
      <li>Agitation Related to Alzheimer's disease*</li>
      <li>Amyotrophic Lateral Sclerosis (ALS)</li>
      <li>Anorexia Nervosa</li>
      <li>Anxiety*</li>
      <li>Arnold-Chiari Malformation</li>
      <li>Autism Spectrum Disorder</li>
      <li>Acquired Immunodeficiency Syndrome (AIDS)</li>
      <li>Cachexia (Wasting Syndrome)</li>
      <li>Cancer</li>
      <li>Causalgia</li>
      <li>Cerebral Palsy</li>
      <li>Chemotherapy-Induced Anorexia</li>
      <li>Chronic Inflammatory Demyelinating Polyneuropathy</li>
      <li>Complex Regional Pain Syndrome, Type 1 and Type II</li>
      <li>Cystic Fibrosis</li>
      <li>Dyskinetic and Spastic Movement Disorders</li>
      <li>Dysmenorrhea</li>
      <li>Dystonia</li>
      <li>Ehlers-Danlos Syndrome</li>
      <li>Elevated Intraocular Pressure*</li>
      <li>Epilepsy</li>
      <li>Fibrous Dysplasia</li>
      <li>Glaucoma</li>
      <li>Hepatitis C*</li>
      <li>Human Immunodeficiency Virus (HIV)</li>
      <li>Hydrocephalus, with Intractable Headache</li>
      <li>Hydromyelia</li>
      <li>Inflammatory Bowel Disease, including Crohn’s Disease</li>
      <li>Interstitial Cystitis</li>
      <li>Intractable Headache Syndrome</li>
      <li>Intractable Skeletal Spasticity*</li>
      <li>Insomnia (moderate to severe)</li>
      <li>Lupus</li>
      <li>MALS Syndrome (Median Arcuate Ligament Syndrome)</li>
      <li>Migraine</li>
      <li>Multiple Sclerosis</li>
      <li>Muscle Spasms*</li>
      <li>Myasthenia Gravis</li>
      <li>Myoclonus</li>
      <li>Muscular Dystrophy</li>
      <li>Nail-Patella Syndrome</li>
      <li>Nausea (severe)*</li>
      <li>Neuro-Bechet’s Autoimmune Disease</li>
      <li>Neurodegenerative Diseases</li>
      <li>Neuropathies</li>
      <li>Neurofibromatosis</li>
      <li>Obstructive Sleep Apnea</li>
      <li>Opioid Use Disorder</li>
      <li>Osteoarthritis</li>
      <li>Osteogenesis Imperfecta</li>
      <li>Post-Traumatic Stress Disorder (PTSD)</li>
      <li>Pain (chronic, severe, debilitating)*</li>
      <li>Pancreatitis (chronic)</li>
      <li>Parkinson’s Disease</li>
      <li>Post-Concussion Syndrome</li>
      <li>Post Herpetic Neuralgia</li>
      <li>Post Laminectomy Syndrome with Chronic Radiculopathy</li>
      <li>Psoriasis and Psoriatic Arthritis (severe)</li>
      <li>Rheumatoid Arthritis (severe)</li>
      <li>Seizure Disorders, including Epilepsy</li>
      <li>Sickle Cell Disease</li>
      <li>Sjogren’s Syndrome</li>
      <li>Spinal Cord Injury or Disease (including but not limited to arachnoiditis)</li>
      <li>Spinocerebellar Ataxia</li>
      <li>Superior Canal Dehiscence Syndrome</li>
      <li>Syringomyelia</li>
      <li>Tarlov Cysts</li>
      <li>Terminal Illness</li>
      <li>Tourette’s Syndrome</li>
      <li>Traumatic Brain Injury</li>
      <li>Ulcerative Colitis</li>
      <li>Vomiting (moderate to severe)*</li>
    </ul>
    <p>*Indicates a symptom or side effect that can benefit from medical cannabis.</p>
    <p>Cannabis has been known to relieve side effects from pharmaceutical drugs such as nausea, appetite stimulation, mood elevation and sleep.</p>
    <p>If you have a rare condition with limited treatment options that is not on this list, discuss your symptoms with your provider. There are states where these symptoms may qualify you for a medical cannabis card.</p>
  </div>`
  },
  {
    title: 'What states allow medical cannabis?',
    content: `<div class="content"> 
    <div class="stateTable">
      <strong>Medical Cannabis is legal in the following states and the District of Columbia:</strong>
      <div class="tablerow">
        <div class="tableColumn">
          <ul> 
            <li>1 Alabama</li>
            <li>2 Alaska</li>
            <li>3 Arizona</li>
            <li>4 Arkansas</li>
            <li>5 California</li>
            <li>6 Colorado</li>
            <li>7 Connecticut</li>
            <li>8 Delaware</li>
            <li>9 Florida</li>
            <li>10 Georgia</li> 
          </ul>
        </div>
        <div class="tableColumn">
          <ul>
            <li>11 Hawaii</li>
            <li>12 Illinois</li>
            <li>13 Louisiana</li>
            <li>14 Maine</li>
            <li>15 Maryland</li>
            <li>16 Massachusetts</li>
            <li>17 Michigan</li>
            <li>18 Minnesota</li>
            <li>19 Mississippi</li>
            <li>20 Missouri</li>
          </ul>
        </div>
        <div class="tableColumn">
          <ul>
            <li>21 Montana</li>
            <li>22 Nevada</li>
            <li>23 New Hampshire</li>
            <li>24 New Jersey</li>
            <li>25 New Mexico</li>
            <li>26 New York</li>
            <li>27 North Dakota</li>
            <li>28 Ohio</li>
            <li>29 Oklahoma</li>
            <li>30 Oregon</li> 
          </ul>
        </div>
        <div class="tableColumn">
          <ul>
            <li>31 Pennsylvania</li>
            <li>32 Rhode Island</li>
            <li>33 South Dakota</li>
            <li>34 Utah</li>
            <li>35 Vermont</li>
            <li>36 Virginia</li>
            <li>37 Washington</li>
            <li>38 West Virginia</li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
          </ul>
        </div>
      </div>
      <span>Source Medicalmarijuana.procon.org. Last updated 6/6/2022.</span>
    </div>
  </div>`
  },
  {
    title: 'Can medical cannabis be recommended for minors?',
    content: `<div class="content">
    <p>Yes. Children and adolescents with a qualifying condition can gain legal access to medical cannabis with a recommendation from their healthcare provider(s) and permission from their legal guardian.  </p>
  </div>`
  },
  {
    title: '<a name="bookmark01" id="bookmark01"></a>What is the law on medical cannabis?',
    content: `<div class="content">
    <div class="stateListing">
      <strong>Please visit your state’s website for cannabis control to find current regulations and qualifying conditions:</strong>
      <div class="tablerow header">
        <div class="tableColumn"><span>State</span></div>
        <div class="tableColumn"><span>Website for Cannabis Control</span></div>
      </div>
      <div class="tablerow">
        <div class="tableColumn">
          <ul>
            <li>Alabama</li>
            <li>Alaska</li>
            <li>Arizona</li>
            <li>Arkansas</li>
            <li>California</li>
            <li>Colorado</li>
            <li>Connecticut</li>
            <li>Delaware</li>
            <li>District of Columbia</li>
            <li>Florida</li>
            <li>Georgia</li>
            <li>Hawaii</li>
            <li>Illinois</li>
            <li>Louisiana</li>
            <li>Maine</li>
            <li>Maryland</li>
            <li>Massachusetts</li>
            <li>Michigan</li>
            <li>Minnesota</li>
            <li>Mississippi</li>
            <li>Missouri</li>
            <li>Montana</li>
            <li>Nevada</li>
            <li>New Hampshire</li>
            <li>New Jersey</li>
            <li>New Mexico</li>
            <li>New York</li>
            <li>North Dakota</li>
            <li>Ohio</li>
            <li>Oklahoma</li>
            <li>Oregon</li>
            <li>Pennsylvania</li>
            <li>Rhode Island</li>
            <li>South Dakota</li>
            <li>Utah</li>
            <li>Vermont</li>
            <li>Virginia</li>
            <li>Washington</li>
            <li>West Virginia</li>
          </ul>
        </div>
        <div class="tableColumn">
          <ul>
            <li><a href="https://amcc.alabama.gov/" target="_blank">https://amcc.alabama.gov/</a></li>
            <li><a href="https://www.commerce.alaska.gov/web/amco/MarijuanaRegulations.aspx" target="_blank">https://www.commerce.alaska.gov/web/amco/MarijuanaRegulations.aspx</a></li>
            <li><a href="https://www.azdhs.gov/licensing/medical-marijuana/index.php" target="_blank">https://www.azdhs.gov/licensing/medical-marijuana/index.php</a></li>
            <li><a href="https://www.healthy.arkansas.gov/programs-services/topics/medical-marijuana" target="_blank">https://www.healthy.arkansas.gov/programs-services/topics/medical-marijuana</a></li>
            <li><a href="https://cannabis.ca.gov" target="_blank">https://cannabis.ca.gov</a></li>
            <li><a href="https://cdphe.colorado.gov/marijuana" target="_blank">https://cdphe.colorado.gov/marijuana</a></li>
            <li><a href="https://portal.ct.gov/cannabis?langauge=en_US&language=en_US" target="_blank">https://portal.ct.gov/cannabis?langauge=en_US&language=en_US</a></li>
            <li><a href="https://dhss.delaware.gov/dhss/dph/hsp/medmarhome.html" target="_blank">https://dhss.delaware.gov/dhss/dph/hsp/medmarhome.html</a></li>
            <li><a href="https://lims.dccouncil.us/Legislation/B18-0622" target="_blank">https://lims.dccouncil.us/Legislation/B18-0622</a></li>
            <li><a href="https://knowthefactsmmj.com" target="_blank">https://knowthefactsmmj.com</a></li>
            <li><a href="https://www.gmcc.ga.gov" target="_blank">https://www.gmcc.ga.gov</a></li>
            <li><a href="https://health.hawaii.gov/medicalcannabis/" target="_blank">https://health.hawaii.gov/medicalcannabis/</a></li>
            <li><a href="https://dph.illinois.gov/topics-services/prevention-wellness/medical-cannabis.html" target="_blank">https://dph.illinois.gov/topics-services/prevention-wellness/medical-cannabis.html</a></li>
            <li><a href="https://www.ldaf.state.la.us/medical-marijuana/" target="_blank">https://www.ldaf.state.la.us/medical-marijuana/</a></li>
            <li><a href="https://www.maine.gov/dafs/ocp/" target="_blank">https://www.maine.gov/dafs/ocp/</a></li>
            <li><a href="https://mmcc.maryland.gov/Pages/home.aspx" target="_blank">https://mmcc.maryland.gov/Pages/home.aspx</a></li>
            <li><a href="https://masscannabiscontrol.com" target="_blank">https://masscannabiscontrol.com</a></li>
            <li><a href="https://www.michigan.gov/mra/" target="_blank">https://www.michigan.gov/mra/</a></li>
            <li><a href="https://www.health.state.mn.us/people/cannabis/index.html" target="_blank">https://www.health.state.mn.us/people/cannabis/index.html</a></li>
            <li><a href="https://msdh.ms.gov/msdhsite/_static/30,0,425.html" target="_blank">https://msdh.ms.gov/msdhsite/_static/30,0,425.html</a></li>
            <li><a href="https://health.mo.gov/safety/medical-marijuana/index.php" target="_blank">https://health.mo.gov/safety/medical-marijuana/index.php</a></li>
            <li><a href="https://mtrevenue.gov/cannabis/" target="_blank">https://mtrevenue.gov/cannabis/</a></li>
            <li><a href="https://ccb.nv.gov" target="_blank">https://ccb.nv.gov</a></li>
            <li><a href="https://www.dhhs.nh.gov/oos/tcp/" target="_blank">https://www.dhhs.nh.gov/oos/tcp/</a></li>
            <li><a href="https://www.nj.gov/cannabis/" target="_blank">https://www.nj.gov/cannabis/</a></li>
            <li><a href="https://www.rld.nm.gov/cannabis/for-consumers/" target="_blank">https://www.rld.nm.gov/cannabis/for-consumers/</a></li>
            <li><a href="https://cannabis.ny.gov" target="_blank">https://cannabis.ny.gov</a></li>
            <li><a href="https://www.health.nd.gov/mm" target="_blank">https://www.health.nd.gov/mm</a></li>
            <li><a href="https://medicalmarijuana.ohio.gov" target="_blank">https://medicalmarijuana.ohio.gov</a></li>
            <li><a href="https://oklahoma.gov/omma.html" target="_blank">https://oklahoma.gov/omma.html</a></li>
            <li><a href="https://www.oregon.gov/olcc/marijuana/pages/default.aspx" target="_blank">https://www.oregon.gov/olcc/marijuana/pages/default.aspx</a></li>
            <li><a href="https://www.health.pa.gov/topics/programs/Medical%20Marijuana/Pages/Medical%20Marijuana.aspx" target="_blank">https://www.health.pa.gov/topics/programs/Medical%20Marijuana/Pages/Medical%20Marijuana.aspx</a></li>
            <li><a href="https://health.ri.gov/healthcare/medicalmarijuana/" target="_blank">https://health.ri.gov/healthcare/medicalmarijuana/</a></li>
            <li><a href="https://medcannabis.sd.gov" target="_blank">https://medcannabis.sd.gov</a></li>
            <li><a href="https://medicalcannabis.utah.gov" target="_blank">https://medicalcannabis.utah.gov</a></li>
            <li><a href="https://ccb.vermont.gov/medical" target="_blank">https://ccb.vermont.gov/medical</a></li>
            <li><a href="https://www.cannabis.virginia.gov" target="_blank">https://www.cannabis.virginia.gov</a></li>
            <li><a href="https://lcb.wa.gov" target="_blank">https://lcb.wa.gov</a></li>
            <li><a href="https://omc.wv.gov/Pages/default.aspx" target="_blank">https://omc.wv.gov/Pages/default.aspx</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>`
  },
  {
    title: 'Where can I take my cannabis medicine?',
    content: `<div class="content">
    <p>Cannabis is generally allowed to be used on private property but state laws for age and possession amounts still apply. Property owners can limit use of cannabis on their private property.  In medically legal states, signs should be posted where open medicating is allowed. Some states permit cannabis to be smoked anywhere that public smoking is permitted. It is important to check your state’s laws, practice being a good neighbor and be mindful of secondhand smoke. </p>
  </div>`
  },
  {
    title: 'Where can I learn more about the current cannabis laws?',
    content: `<div class="content">
    <p>Every state has their own laws surrounding medical cannabis and the regulations vary from state to state. These variations include the qualifying conditions and how much medical cannabis is legal to have in possession at one time. For up-to-date information on state laws, check with your state government page. To help make it easy for you, Bennabis Health has compiled a link for each state (To help make it easy for you, Bennabis Health has compiled a link for each state listed above, under <a href="#bookmark01" class="hyperlink">What is the law on medical cannabis?</a>), or click the link(s) below for an interactive map detailing legalization, medical use, recreational use and more:</p>
    <p><a href="https://tinyurl.com/3h772dtn" target="_blank">Interactive Map</a></p>
  </div>`
  }
];

const curious = [
  {
    title: 'What is the Cannabis Plant? ',
    content: `<div class="content">
    <p>Cannabis is a flowering plant that grows on a fibrous stalk. As the plant grows, leaves and flowers (buds) form. Crystal-like appendages called trichomes, then cover the leaves and flowers. Inside the trichomes are glands containing a resinous oil. This oil contains naturally occurring chemical compounds making it the most medicinal part of the plant. Many different parts of the plant are used for medicinal purposes including the leaves, flowers/buds, and even the roots, each having a vast array of medicinal properties.</p> 
    <p>There are over 100 cannabinoids (such as CBD and THC), in the cannabis plant. These compounds, including certain terpenes and flavonoids, differentiate cannabis from other plants. Each has its own unique effect on the body and can provide a variety of therapeutic effects when consumed.</p>
    <p>There are different varieties of the cannabis plant, often referred to as ‘strains.’ Multiple species are grown and designed to provide specific symptomatic relief to consumers to help treat symptoms of certain conditions.</p>
    <p>Cannabis Indica and Cannabis Sativa are two of the cultivars, or species of cannabis, commonly grown by dispensaries and caregivers to produce cannabis medicine. The names of the plants that you see displayed on the dispensary menu are based on their DNA or chemical compound makeup by using a scientific classification system. </p>
    <p>Indica cannabis plants are usually shorter and more ‘bushy’ than Sativa plants, with darker broader leaves and denser buds are produced. The cannabinoid profile is the percentage of THC or CBD, etc. and depends on the genetics of the plant. Indica plants are usually found in northern climates where the plant needs fewer sunlight hours per day to grow successfully.</p>
    <p>Sativa is the tallest growing of the three major cannabis plant types and can grow up to 20 feet high. Sativa strains have thin, finger-like leaflets and narrow leaves with long buds and originate from hot climates with more extended periods of sunlight. These plants thrive in hot weather.</p>
    </div>`
  },
  {
    title: 'What is the difference between hemp and cannabis?',
    content: `<div class="content">
    <p>Cannabis and Hemp are both part of the cannabis plant family, but the compounds of these plants are vastly different. There is often confusion that Hemp is CBD and Cannabis is THC. This is not necessarily the case.</p>
    <p><strong>Cannabis</strong> is generally cultivated for medicine and used in the treatment of certain conditions, symptoms, and side effects. While some medicinal marijuana is considered CBD only (99% or more), it often contains combinations of CBD, THC, and other cannabinoids. All cannabis medicine contains trace amounts of THC (greater than 0.3%).</p>
    <p><strong>Hemp</strong> on the other hand, is very promising in terms of textiles, agriculture, food, clothing, bio plastics, biofuel, insulation, and more. Hemp production was legalized in the federal 2018 Farm Bill. It contains only trace amounts (less than 0.3%) of THC. While Hemp is CBD only, it does not contain high concentrations of CBD. It requires a lot of hemp to create a small amount of CBD and does not contain the medicinal compounds of cannabis.</p>  
  </div>`
  },
  {
    title: 'How does cannabis work in your body?',
    content: `<div class="content">
    <p>Cannabis interacts with our Endocannabinoid System (ECS), which is an endogenous, physiologic system that aides in maintaining human health. This molecular system is responsible for homeostasis and balancing some of our most critical bodily functions and processes in our body. It regulates and controls things including appetite, metabolism, communication between cells, memory, emotional processing, sleep, temperature control, pain control, inflammatory and immune response, and more. </p>
    <p>The exact process of how cannabis works in the ECS is still being researched, but we do know that patients feel the effects of the compounds in cannabis when those compounds connect with specific parts of our cells.</p>
    <p>Endocannabinoid receptors are located throughout the body, specifically in the brain, CNS (Central Nervous System) connective tissues, organs, glands, and immune cells. This may be one of the reasons cannabis is so effective in treating a variety of symptoms and conditions.</p>
    </div>`
  },
  {
    title: 'What are cannabinoids?',
    content: `<div class="content">
    <p>Cannabinoids are the active chemical compounds found in the cannabis plant and are very similar to naturally occurring chemicals that the body makes.  As a matter of fact, while we mainly consume cannabinoids from the cannabis plant, our bodies make their own cannabinoids, as well. </p>
    <p>Cannabinoids bind to cannabinoid receptors located all throughout the human body, also known as docking sites. This alters neurotransmission in the brain and depending on the dosage and cannabinoid profile, can give a patient the relief they need.  </p>
    <p>Research and patient feedback suggests cannabinoids can: Control nausea and vomiting and other symptoms associated with chemotherapy, reduce inflammation, relieve pain, reduce anxiety and the symptoms of PTSD, help with opioid withdrawal, relax muscles, act as neuroprotectants, stimulate appetite, and more.</p>
    <p>The concentrations and combinations of the cannabinoid profiles vary from plant to plant and differ from strain to strain. Some very well-known cannabinoids are Delta-9-tetrahydrocannabinol (THC) and cannabidiol (CBD). THC produces a psychoactive effect people feel when they smoke marijuana or eat foods containing it. CBD is more of an anticonvulsant, neuroprotectant, and antioxidant. Medical researchers usually focus on the health effects of the two, but research is ongoing to understand the great potential of this plant as medicine and cannabinoids overall.</p> 
    </div>`
  },
  {
    title: 'What are terpenes? ',
    content: `<div class="content">
    <p>Terpenes are chemical compounds that give cannabis its unique taste or smell. There are over 150 types of terpenes in different types of cannabis. The concentrations and combinations of terpenes vary from plant to plant and among the different strains of cannabis. Terpenes are diverse and can be mood altering as well as provide therapeutic effects</p>
    <p>An example of a cannabis terpene would be Myrcene, also found in hops, mangos, and lemongrass. Myrcene is known to enhance the effects of cannabis. An example of a non-cannabis terpene would be lavender, known for its calming effects.</p>
    </div>`
  },
  {
    title: 'What is the endocannabinoid system?',
    content: `<div class="content">
    <p>Throughout your body, you have an advanced system that specially processes cannabinoids produced naturally in your body, as well as the cannabinoids you ingest from the cannabis plant in a safe healthy manner. This system is called the endocannabinoid system, also referred to as the ECS. </p>
    <p>Identified in the early 1990’s by researchers exploring THC, the ECS is a vast molecular cell-signaling system that is essential in promoting overall health and maintaining equilibrium, or homeostasis in the body. This system involves a combination of endocannabinoids, enzymes, and cannabinoid receptors that are involved in regulating certain functions in the body of all mammals, including pain, stress, appetite, energy metabolism, cardiovascular function, reward and motivation, reproduction, and sleep.</p>
    <p>This physiologic system contains a collection of cell receptors that the molecules correspond to called CB1 and CB2 receptors. In order for a therapeutic response to take place, specific cannabinoids attach to the specific receptor sites where, through the use of neurotransmitters and lipids, there is a postsynaptic response. The neuron receives the message, and an effect is felt.</p>
    <p>CB1 receptors are mostly found on nerve cells, in the brain, spinal cord and in other organs. THC fits into these receptors. This helps us understand how THC can help aid in managing pain. </p>
    <p>CB2 receptors are found in reproductive organs, the Immune System and the peripheral Nervous System, which are all the nerves outside the brain and spinal cord. CBD fits into these receptors. This helps us understand why CBD may help with conditions that cause tremors of the hands.</p>
    <p>Through science we continue to learn more about the role of the ECS in regulating the body. What we know is:</p>
    <ul>
      <li>A healthy endocannabinoid system is essential.</li>
      <li>It is important that we recognize how it works and how to maintain it.</li>
      <li>The best way to maintain a healthy ECS is to introduce plant-based cannabinoids.</li>
      <li>The ECS helps to facilitate the generation of new neurons and is involved in neuroprotection, aiding in generating new brain cells.</li>
      <li>The ECS can aid in regulating blood sugar, metabolism, nutrient transport, and aid in controlling certain memory processing, synaptic plasticity (our primary mechanism for learning and memory), motor activity, energy storage and even bone regrowth.</li> 
      <li>The potential health benefits of cannabis medicine are numerous.</li> 
    </ul>
    </div>`
  },
  {
    title: 'What is the difference between CBD and THC?',
    content: `<div class="content">
    <p>THC and CBD are the two most well-known cannabinoids.</p>
    <p>THC, or tetrahydrocannabinol, also known as delta-9-tetrahydrocannabinol, has a vast array of therapeutic effects. This substance is psychoactive, meaning it can change brain function, mood and/or behavior.  The concentration of THC in a medical cannabis product usually varies from 1%-upwards of 90%, depending on what condition the patient is treating.</p>
    <p>CBD, or cannabidiol, is non-psychoactive and more known as an anti-inflammatory, anti-spastic, anticonvulsant, and neuroprotectant.</p>
    </div>`
  },
  {
    title: 'What is the difference between medical and recreational marijuana? ',
    content: `<div class="content">
    <p>Medical cannabis and recreational or “Adult Use” cannabis are similar in many ways, but there are also vast differences. It is important to be aware of the differentiating factors which include pricing, quality, product availability, and legalities. These differentiators between the two are driven by the purpose. The reason for using medical cannabis is the key factor distinguishing medical from recreational marijuana.</p>
    <p>Here are a few other key points to remember:</p>
    <ul>
      <li>Cannabis is medicine when a doctor recommends it.</li>
      <li>There are more states that allow medical cannabis than recreational, so it’s best to remain a patient to ensure safe access to your medication.</li>
      <li>Price is often lower for medical cannabis patients, typically because of differences in tax laws in the state (recreational users pay higher taxes when purchasing cannabis).</li>
      <li>Patients often have access to a wider variety of products and medical strains, educational content and supportive services specifically designed to guide their journey to wellness and optimal health.</li>
      <li>Cannabis cardholders can assign or designate a ‘caregiver’ to help support them through the process of exploring this medicine and accompany them to the dispensary.</li>
      <li>Medical cannabis is grown in a controlled environment with rigorous quality assurance procedures from cultivation to packaging, as well as testing guidelines that help ensure a high-quality product.</li>
      <li>There are states that allow patients in substance abuse treatment programs to have access to medical cannabis as an alternative treatment to pharmaceutical medications.</li>
      <li>Dispensaries may offer products to their patient base first or maintain certain products exclusively for medical users, while some dispensaries aren’t open to a recreational market.</li>
      <li>Recreational cannabis use is strictly for those age 21 and older. Many states have medical programs allowing patients of all ages, including pediatrics to legally access medical cannabis with a recommendation from their healthcare provider.  </li>
      <li>Medical marijuana cards provide certain legal protections to patients and their families not provided in the recreational market, ranging from child custody to employment.</li> 
    </ul> 
    </div>`
  },
  {
    title: 'What determines how cannabis affects a person?  ',
    content: `<div class="content">
    <p>Although there are some common trends among certain types of cannabis and methods of ingestion, medical marijuana affects each patient individually. How cannabis affects a person depends on many factors, including but not limited to: </p>
    <ul>
      <li>The type of cannabis ingested</li>
      <li>The method of consumption</li>
      <li>A patient’s previous experience with cannabis</li>
      <li>Frequency of use and tolerance to cannabis</li>
      <li>Other pharmaceutical prescriptions taken</li>
      <li>Blood pressure</li>
      <li>Other substances (such as alcohol or illegal drugs)</li>
      <li>Weight</li>
      <li>Height</li>
      <li>Age</li>
      <li>Other factors</li>
    </ul>   
    </div>`
  },
  {
    title: 'What are common types of cannabis?',
    content: `<div class="content">
    <p>There are many different varieties of the cannabis plant, and the type you're using will determine the effects you’ll feel. Different cultivars, or strains, have different effects on the mind and body when ingested or applied.</p>
    <p>There are three original species of the cannabis plant: Indica, Sativa and Ruderalis.</p>
    <ul>
      <li>Indica cultivars are generally considered more of a body medicine that is calming and relaxing. This type is known for its ability to relieve anxiety, insomnia, and pain, relax muscles and stimulate appetite.</li>
      <li>Sativa varieties are known for their uplifting and energetic effects and can offer mood elevation. Some blends may be more cerebral in their effect while others are more physical.</li>
      <li>Ruderalis species are in its own category but there has been some debate that ruderalis is similar to sativa. This plant is not often bred by cultivators because of a low THC content and lack of medicinal properties.</li>
    </ul>
    <p>All cannabis contains a variety of cannabinoids and terpenes, but generally THC or CBD are the most abundant in the plant. Some strains are THC dominant, others are CBD dominant, and most contain traces of other helpful cannabinoid profiles, such as CBG (cannabigerol) or CBC (cannabichromene). Some patients prefer Indica only cannabis, while others prefer Sativa only. Another option for patients are Hybrids, a crossbreed or blend of Sativa and Indica. These varieties have become known to be very effective and have promising medicinal uses as patients report a great deal of relief from their conditions while using these strains.</p>
    <p>Not all dispensaries will have the same product names or strains. Becoming familiar with the compounds in the plant will help guide you in understanding all your options on a dispensary menu. Keep in mind the names of the medicine are not as important as the combination of the compounds identified on the label.</p>
    <p>No matter which strain you select, the amount you consume and the method of consumption generally determines your experience. Many cannabis professionals suggest starting out with micro-dosing, or going ‘low and slow’, where you ingest a small amount of cannabis medicine and wait to see how that affects you before you consume more. Depending on the cannabis product you take, you may need to give it some time to feel the effects. For example, marijuana edibles could take anywhere from minutes to hours to be effective. Take it slow in discovering which strains and methods work best for you.</p>
    <p>Cannabis is essential to many patients, and that's why at Bennabis Health, we offer reliable services to patients who have been diagnosed with illnesses whose symptoms can be alleviated by medical cannabis. We are proud to have a team comprised of some of the most compassionate and knowledgeable cannabis professionals across the globe. Contact us for more information and join Bennabis Health today.</p>    
    </div>`
  },
  {
    title: 'What are other names for cannabis?',
    content: `<div class="content">
    <p>Cannabis goes by many names. Some may seem more obscure than others, but they are all referring to the same basic plant.</p>
    <p>The most common terms used for cannabis are, weed, pot, ganja, marijuana, nugs, bud, broccoli, cheeba, trees, grass, Mary Jane, flower, chronic, or herb.  Some other slang terms are, dank, sticky icky, dope, devil’s lettuce, gas, hash, hot stick, jay, jolly green, fire, skunk, roach, reefer, kryptonite, gauge, jive, wacky tobacky, a salad, or 420.</p>
    <p>Some people refer to it based on quality, like high grade, or how it’s smoked such as a joint, blunt, fatty, spliff, doobie, or happy cigarette. Other times it may be referred to by where it’s grown, such as homegrown, hydro, landrace, indoor or outdoor, and even by its potency, such as sweet sinsemilla.</p>
    <p>No matter how it’s addressed, it is still cannabis. It still comes from the same plant. Meanwhile, we have learned a lot about that plant over the years and how it provides a medicinal benefit. What we know for certain is that it’s not the same ‘reefer’ or ‘Thai sticks’ from the 70’s. It’s not ‘dirt weed.’  For many people, it’s safe, effective, and life-changing medicine.</p>
    <p>This famous quote from Romeo and Juliet by William Shakespeare may help us realize that it really doesn’t matter what we call it. Cannabis is medicine. “What’s in a name? A rose by any other name would smell as sweet.” If a name is what is separating you from something that could help you or a loved one, take some time to learn more about it.</p>
    <p>It’s important to have a reliable resource to learn about cannabis medicine. Bennabis Health is here to keep you up to date and informed with the latest in medical cannabis education and even access to a discount at Network Dispensaries.</p>
    <p>Become a member by clicking <a href="https://bennabishealth.com/enroll">here</a> and become part of the Bennabis Health Community today.</p>
    </div>`
  },
  {
    title: 'Besides smoking medical cannabis, how else can I take it?',
    content: `<div class="content">
    <p>There are many choices when opting to consume medical cannabis, from inhaled options to topical applications and a variety of ingestible forms like edibles and tinctures. It is the combination of the dosage and how you ingest the medicine that determines how quickly you will feel the effects of the medicine, and how long it will last. When you understand the different methods, how they work, for how long, and what conditions each method may be best for, you can truly have a beneficial experience with your cannabis medicine.</p>
    <p>Visit (here) to become a Bennabis Health member where you can view our Methods of Consumption series. This will help you learn more about the different ways to use medical marijuana, including which method may be right for you and how to get the most benefit from your medicine. For now, let’s give a brief overview.</p>
    <p><strong>Inhaled cannabis is perhaps the most well-known method.</strong></p>
    <p>While the American Lung Association suggests that smoking of any kind is bad for lung health, the benefits of inhaling cannabis often outweigh the risks. It’s important to understand that what we inhale, and how we inhale it, plays a role in determining the benefits and risks.</p>
    <p>Inhaled medical cannabis comes in a large variety of options, including herbal flower, concentrates from resins, isolates, dabs, and full concentrates, as well as vaporizers and inhalers. Often dispensaries will have prefilled cartridges with cannabis extracts in a variety of ratios, dosages and cannabinoids to suit individual patient’s needs. You can also look for pre-rolls, which are joints or cannabis cigarettes, and other devices for your medicating experience.</p>
    <p>There are many reasons a patient might choose this method. For instance, it is the quickest way to get the medicine in your system and is known to help with the symptoms of cancer, such as pain and nausea. It has been known to be useful in rescue situations and breakthrough pain scenarios.</p>
    <p>Dangers to smoking or vaping cannabis include: bronchitis, mucus production, cough, wheezing, risk of infection or heart arrhythmias. Researchers have determined that the effects of vaping marijuana are much stronger than smoking it. </p>
    <p><strong>Next are some great options for patients who don't want to inhale their medication.</strong></p>
    <p>Topical applications come in a variety of options ranging from salves, balms, oils, lotions, bath bombs, butters and creams, patches, and more, offering both short and long-term relief. These products are applied to skin and can treat many conditions. </p>
    <p>Ingestible cannabis are forms of cannabis that can be taken orally, such as edibles, capsules, and tinctures. They are many discrete and portable options, some of which are even odor free… except for those yummy smelling chocolates and brownies.  </p>
    <p>Edibles can come in multiple forms, from ready-to-drink cannabis infused beverages and powered drink mixers, to gummies, cookies, candies, and ice creams, to sprays, breath strips and mints, butters and oils. Bases, like butters, oils and salad dressings, can be useful as well. The beauty of the bases is that they can be combined with your every-day cooking habits, your morning coffee or before bed tea, and easily incorporated into meal planning for special diets. </p>
    <p><strong>Some of the most fool proof methods of ingesting cannabis are capsules and tinctures. </strong></p>
    <p>Medical cannabis capsules are just that, medical marijuana in a pill or capsule form. This very convenient method of ingesting cannabis comes in a variety of dosages, and with different ratios of cannabinoids. Pills or capsules generally come in a pre-labeled bottle, with individual doses and also work on a variety of conditions. Once a proper dose is established, this method leaves little room for error. </p>
    <p>Another form of capsules are suppositories. These are made available to patients with digestive issues and other conditions or needs such as severe pain or nausea. Suppositories can be used to ensure the entire dose is absorbed and the potency of the medicine isn’t lost in the digestive process. </p>
    <p>Lastly, cannabis tinctures and sublingual applications generally come in a liquid or spray form and are easy to dose. Drops are generally placed under the tongue or tacked to the gums. Some tinctures can be added to feeding tubes. This is also a discrete, generally odorless method that comes in a variety of dosages and ratios. Being able to go low and slow with this form of medicine allows a patient to gauge its effects in real time. </p>
    <p>Many of these forms of medical cannabis have been reported to help alleviate symptoms like chronic pain, muscle pain and stiffness, digestive issues, insomnia, and to treat the symptoms of terminal conditions, cancer treatment side effects, help to induce an appetite, reduce anxiety, and more. </p>
    <p>It's important to note that while some experiences are similar and there are guidelines we can follow, every patient is different. What works for one may not be the remedy for another. </p>
    <p>Consider trying a capsule or drop of liquid under your tongue, just like you may be willing to eat a brownie or smoke a joint. You may be surprised how the differently the methods work. Become a member and empower yourself with the knowledge to live your best life, today!</p>
    </div>`
  },
];

const ready = [
  {
    title: 'What are the benefits of keeping a patient journal?',
    content: `<div class="content">
    <p>Journaling is a good way for you and your healthcare provider(s) to get to know each other better. The information in your journal can be utilized when collaborating with your healthcare team. It helps you and your team of caregivers stay well informed.</p>
    <p>It allows you to discuss the symptoms you are experiencing, identify obstacles or things you may be struggling with, set goals, create personalized care plans and solutions, as well as modify plans accordingly. It can aid in building a professional relationship between you, your providers, and the dispensary staff. A journal can also be used to help you decide where to start, what products and methods are working for you, proper cannabis dosing and how often to ingest your cannabis medicine, what to add or perhaps, take away from your medicine regimen.</p>
    <p>Bennabis Health has created a comprehensive printable journal and health tracker for our members to track their progress with cannabis and to help manage current conditions. Become a member today and gain access to our medical marijuana educational resources.</p>
    </div>`
  },
  {
    title: 'What are the different ways to consume medical marijuana?',
    content: `<div class="content">
    <p>There are a variety of cannabis products and many ways to apply or ingest cannabis medicine. The flower (or bud from
    the cannabis plant) can be transformed into endless medical cannabis products such as: tinctures, capsules, lotions,
    salves, edibles, vaporizers and inhalers, drinks, transdermal patches, and more. These products may alleviate symptoms of a
    large variety of conditions.</p>
    <p>The methods of consumption that work best differ from patient to a patient. It takes some time to decipher how often
    a patient should use them, how they will make a patient feel, and if the cannabis consumption method will help their symptoms.</p>
    <p>The main categories or methods of consumption are: Topicals, Sublinguals, Edibles, and Inhaled Products.</p>
    <div class="blockquote">
      <ul>
        <li><em>Topical products:</em> Lotions, transdermal patches to salves.</li>
        <li><em>Sublinguals:</em> Tinctures and other products that can be administered under the tongue, or buccal products
        like oils that can be applied to the gums, and even oral sprays.</li>
        <li><em>Edibles:</em> Capsules, gummies, cookies, sauces, candy, drinks, cannabis bases like butter or ghee to be
        used as options for infusing cannabis medicine into your favorite recipes, breath-mints, and more.</li>
        <li><em>Inhaled products:</em> Cannabis bud or flower, concentrates, vaporizers, and even inhalers. </li>
      </ul>
    </div>
    <p>Alternatively, synthetic cannabis products are made in a laboratory and intended to replicate some of the naturally occurring compounds in the cannabis plant. Some FDA-approved examples of these are Marinol (dronabinol), Syndros (dronabinol), and Cesamet (nabilone) which are generally prescribed as an antiemetic and appetite stimulant. </p>
    </div>`
  },
  {
    title: 'Is it safe to use more than one form of medical marijuana at a time?',
    content: `<div class="content">
    <p>The short answer is yes, but there’s more to it...</p>
    <p>When first starting medical cannabis, we suggest:</p>
    <ul>
      <li>Using a “low and slow” strategy:</li>
      <li>Trying only one cannabis product at a time at a small dosage amount. </li>
      <li>Allowing one dosage to run its course before adding more medicine, as this can lead to overconsumption.</li>
      <li>Increasing or decreasing your dose accordingly based on your provider’s recommendation.</li>
      <li>Tracking and journaling to help learn the effects and proper dosing in a safe way. This can also improve communication
      with your healthcare team.</li>
    </ul>
    <p>This is important to do with care and alongside a cannabis journal to track the dosing and results.</p>
    <p>It takes time to figure out this situational dosing and again having a journal to track results can be very handy.
    Experienced users may choose to rely on different methods of consumption and medicine in different dosages throughout
    the day or night to meet their needs, to handle flares, break-out symptoms, or in emergency situations.</p>
    <p><em>Become a Bennabis Health member for access to our customized health tracker and printable journal designed to support this process.</em></p>  
    </div>`
  },
  {
    title: 'How does medical cannabis dosing work?',
    content: `<div class="content">
    <p>Medical cannabis can have a variety of ratios of CBD to THC, sometimes ranging from 1:1 to 24:1. </p>
    <p>Examples of a tincture containing CBD and THC in different ratios are shown below:</p>
    <ul id="dosingwork">
      <li><span>1:1</span>5mg CBD and 5mg THC</li>
      <li><span>24:1</span>24mg CBD and 1mg THC</li>
      <li><span>2:3</span>10mg CBD and 15mg THC</li>
      <li><span>3:2</span>15mg CBD and 10mg THC</li>
    </ul>  
    </div>`
  },
  {
    title: 'What is The Entourage Effect?',
    content: `<div class="content">
    <p>There are compounds in the cannabis plant called cannabinoids. Some examples of these compounds are CBD & THC.
    Flavonoids and terpenes are also in the cannabis plant. Each of these compounds has its own unique effects and
    benefits and their behavior can change in the presence of other compounds.</p>
    <p>The entourage effect is a theory that states that the compounds in cannabis work together synergistically, and when
    used together in different combinations and ratios, they often complement each other. This produces more effective
    therapeutic relief than when the compounds are taken alone. Since each patient's needs and conditions vary, it is
    important to pay attention to the pairing or combination of compounds and the resulting effects. </p>
    <p>Full spectrum cannabis medicine has multiple cannabinoids and other compounds. Proponents of the entourage theory
    believe full spectrum cannabis is best for patients. In addition to providing a more therapeutic effect, this theory
    also suggests that when we add certain compounds of cannabis plants together, it can also lessen the potential side
    effects of other compounds. One example of this would be adding a small amount of CBD to your THC to counteract
    potential anxiety THC may cause. </p>
    <p>When a patient develops a tolerance to a certain cannabinoid, a good way to provide relief is to use combination
    therapy and create the entourage effect. Using this combination therapy approach can help keep a patient’s tolerance
    low, reducing the need to use a high dose of one cannabinoid. Most dispensaries will have many products available to
    you with these different strains and ratios tailored to suit your needs to help avoid building tolerance and
    resistance to your medication, allowing you to experience the entourage effect and feel at your best.</p>
    </div>`
  },
  {
    title: 'What is the difference between Indica and Sativa strains of cannabis?',
    content: `<div class="content">
    <p>Historically, Indica and Sativa strains were bred independently. Indica is known for its calming, relaxing, sedating, and pain-relieving effects.   Sativa is known for its cerebral, energetic, stimulating, and uplifting effects. Cannabis breeders often create hybrid strains, or a cross between both Indica and Sativa, giving patients well rounded therapeutic options.</p>
    </div>`
  },
  {
    title: 'What do I need to know to safely ingest medical cannabis?',
    content: `<div class="content">
    <p>Medical cannabis is safe and effective for many patients when taken in the proper dose, form, environment and with
    the proper understanding of the medicine. Just like with any other medication in your treatment plan, the benefits
    must outweigh any risks.</p>
    <p>There are no known cases of lethal overdoses from consuming cannabis. However, consumers can take too much.
    Overconsumption may leave patients with some undesirable side effects, ranging from confusion, anxiety, paranoia,
    panic, increased heart rate or increased blood pressure, to nausea, vomiting, lethargy, delusions or hallucinations.</p>
    <p>It is so important to go “low and slow” when starting a new dosage or method of consumption until you know how
    medical marijuana will affect you. Always ingest any new medicine in the safety of your own home with someone you
    trust, who understands you, your symptoms and medical conditions.</p>
    <p>Be careful when combining cannabis and other substances such as alcohol. Using multiple substances at the same time
    can lead to impairment or even risk of physical harm. Not only is it important that you ingest your medical cannabis
    safely, but storage is also critical, and it should not be accidently exposed to extreme temperatures. Store your
    medication in a light-proof jar to help maintain the medicinal properties and potency. Consider locking your medicine
    in a safe.</p>
    <p>While cannabis has been reported to help treat the symptoms of many conditions, some patients have reported
    interactions with other medications. Be sure to keep track of your consumption using the Bennabis Health Patient
    Journal available to members. This detailed documentation of your consumption as well as side effects and results, can
    be helpful in keeping your healthcare team informed.</p>
    <p>Bennabis Health is available as a resource to help our members learn about these topics and using medical cannabis in
    further detail. Consider becoming a member today.</p>
    </div>`
  },
  {
    title: 'What are the potential side effects of medical cannabis? ',
    content: `<div class="content">
    <p>Marijuana can affect movements, judgements and coordination, which can lead to unintentional injury. Be mindful when
    consuming marijuana with other substances as that may lead to greater impairment. Research is ongoing as to whether
    cannabis might affect IQ and mental function in the teenage years, so it’s always important to be sure the benefits
    outweigh the risks. </p>
    <p>Most side effects can be minimized when taking care that the proper dose and type of cannabis is consumed. </p>
    <p>Side effects that have been reported by consumers and healthcare providers include: </p>
    <ul>
      <li></li>
      <li>Dry mouth</li>
      <li>Dry and/or bloodshot eyes</li>
      <li>Rapid heartbeat</li>
      <li>Low Blood Pressure</li>
      <li>Dizziness</li>
      <li>Depression</li>
      <li>Hallucinations</li>
      <li>Sedation</li>
      <li>Increased Appetite</li>
      <li>Interaction with certain medications</li>
      <li>Respiratory distress</li> 
    </ul>  
    </div>`
  },
  {
    title: 'Can I lethally overdose from taking too much cannabis? ',
    content: `<div class="content">
    <p>No one has ever reported a lethal overdose from cannabis.</p>
    </div>`
  },
  {
    title: 'Is it possible to overdose or have a bad reaction to cannabis? ',
    content: `<div class="content">
    <p>Yes. While it is true that a fatal overdose caused solely by cannabis has never been reported, cannabis is not
    completely harmless or without side effects. It is possible to over-consume or take too much cannabis medicine which
    can lead to some undesired effects. This is why it is so important to go “low and slow”, understand your methods of
    consumption, and get your dose right.</p>
    <p>Typical signs of over-consumption may include: </p>
    <ul>
      <li>Confusion, Anxiety, Panic, or Paranoia</li>
      <li>Increased Heart Rate</li>
      <li>Increased Blood Pressure</li>
      <li>Delusions or Hallucinations</li>
      <li>Nausea or Vomiting</li>
      <li>Lethargy</li>
    </ul> 
    </div>`
  },
  {
    title: 'What are the effects of mixing cannabis with alcohol, tobacco, or other prescription medicines?',
    content: `<div class="content">
    <p>It is important to understand the medicine you are taking and how it impacts you. Using alcohol and cannabis at the same time can result in greater impairment and can pose a risk of physical harm. Always talk with your doctor about any medications you are taking or thinking about taking and the possible side effects when mixed with other things, such as cannabis.</p>
    </div>`
  },
  {
    title: 'Has the FDA approved medical cannabis? ',
    content: `<div class="content">
    <p>There are three synthetic cannabis-related products that are FDA approved. They are Marinol (dronabinol), approved in 1985, Cesamet (nabilone), approved in 2006, and Syndros (dronabinol), approved in 2016.</p>
    <p>These synthetic cannabinoids are man-made and only available with a prescription from a licensed healthcare provider. Marinol, Syndros and Cesamet are designed to mimic delta-9- tetrahydrocannabinol (known as THC) and used to treat symptoms such as nausea associated with cancer chemotherapy and for the treatment of anorexia associated with weight loss in AIDS patients.</p>
    <p>In 2018, the FDA approved Epidiolex. This was the first FDA-approved drug that contains a purified drug substance derived directly from the cannabinoids of the cannabis plant. It is an oral solution that is processed and purified in a lab until it contains 99% CBD only. Epidiolex is used in the treatment of rare seizure and epileptic disorders such as, Lennox-Gastaut and Dravet Syndrome, in patients two years of age and older. This is the first FDA approval of any treatment of patients with Dravet syndrome.</p>
    <p>Many patients report better results from medicine made from the whole plant rather than the synthetic medicines the FDA has approved. Much more medical marijuana research needs to be done and those efforts continue globally.</p>
    </div>`
  },

]

const workersCompensation  = [
  {
    title: 'In what states does Bennabis Health offer a Workers’ Compensation program?',
    content: `<div class="content">
    <p>Bennabis Health offers Workers’ Compensation programs for patients in New Jersey and New Mexico.</p>
    <p>Not in our Workers’ Compensation coverage area? <a href="/newsletter">Sign up</a> for Bennabis Health email updates to receive all the latest information as our coverage area expands</p>
    </div>`
  },
  {
    title: 'What are the benefits of the Bennabis Health Workers’ Compensation program?',
    content: `<div class="content">
    <p>Patients who qualify for and enroll in a Bennabis Health Workers’ Compensation program can purchase their approved medical cannabis at a <a href="/network-dispensary">Network Dispensary</a> with <strong>$0 out of pocket due</strong> at the point of sale.</p>
    <p>In addition, all members in the Bennabis Health Workers’ Compensation program receive access to our original and reliable medical cannabis educational content and direct access to our team of experts.  Coming soon we will offer webinars, support groups, and medical cannabis cooking classes.</p>
    </div>`
  },
  {
    title: 'How do I qualify for the Bennabis Health Workers’ Compensation program?',
    content: `<div class="content">
    <p>Currently available in New Jersey and New Mexico: You qualify for medical marijuana for your Workers’ Compensation claim if your employer permits your access to it for your injury, or if the Workers’ Compensation Court orders your employer to do so. Everyone who qualifies for medical marijuana through their Workers’ Compensation claim also qualifies for the Bennabis Health Workers’ Compensation program.</p>
    </div>`
  },
  {
    title: 'I qualify for the Bennabis Health Workers’ Compensation program.  How do I enroll?',
    content: `<div class="content">
    <p>Enrollment in our Workers’ Compensation program is through your Workers’ Compensation adjuster or your attorney.  Please have your adjuster or attorney contact Bennabis Health at <a href="mailto:memberservices@bennabis.com">memberservices@bennabis.com</a> for additional information.</p>
    </div>`
  },
  {
    title: 'Is there an enrollment fee for a Bennabis Health Workers’ Compensation program?',
    content: `<div class="content">
    <p>No.  The Bennabis Health Workers’ Compensation program is free for all qualified Workers’ Compensation patients.</p>
    </div>`
  },
  {
    title: 'Do any limitations apply to my medical cannabis purchases?',
    content: `<div class="content">
    <p>Purchases must be made at a <a href="/network-dispensary">Network Dispensary</a> and are limited to approved Workers’ Compensation medical cannabis only.  Purchases of medical cannabis through the Bennabis Health Workers’ Compensation program may be subject to limitations by your adjuster or the Workers’ Compensation Court.</p>
    </div>`
  },
  {
    title: 'How will the Network Dispensary know that I am a Bennabis Health Workers’ Compensation member and eligible for $0 out of pocket at the point of sale?',
    content: `<div class="content">
    <p>Once enrolled, you will receive an email invitation to verify your account and login.  Your program membership is active immediately.  Simply login to your account on your mobile phone or device and show your Membership screen to the <a href="/network-dispensary">Network Dispensary</a> representative at check in and/or purchase.</p>
    <p>There is no need to carry a Bennabis Health identification card but if you prefer, you may print a copy of the card. Please note that if opting to print, you must do so on the day you visit the dispensary as the printout will be date stamped.</p>
    <p>The name and medical card number on your state issued medical cannabis card must match the information on the Bennabis Health ID card on your device.</p>
    </div>`
  }
]

export default accordionData;

export { curious, ready, workersCompensation };