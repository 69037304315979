import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import PromotionalBanner from '../masterLayout/PromotionalBanner'
import Referral from '../masterLayout/Referral'
const Home = () => {
 const [bannerVisibility, setBannerVisibility] = useState()
 const [referralModal, setReferralModal] = useState()

 // FUNCTION TO CHANGE STATE REFERRAL MODAL
 function closeModal(setValue) {
  setReferralModal(setValue)
 }

 // USEEFFECT LOAD WIDGET PROMOTIONAL BANNER
 useEffect(() => {
  const loadWidgetBanner = async () => {
   try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/widgets`)
    const responseData = await response.json()
    setBannerVisibility(responseData.data[0].promotional_banner)
   } catch (e) {
    console.log(e)
   }
  }
  loadWidgetBanner()
 }, [])

 // USEEFFECT LOAD WIDGET REFERRAL MODAL
 useEffect(() => {
  setTimeout(() => {
   const loadWidgetReferral = async () => {
    try {
     const response = await fetch(`${process.env.REACT_APP_API_URL}/widgets`)
     const responseData = await response.json()
     setReferralModal(responseData.data[0].referral_modal)
    } catch (e) {
     console.log(e)
    }
   }
   loadWidgetReferral()
  }, 5000)
 }, [])

 return (
  <React.Fragment>
   <Helmet>
    <title>Bennabis Health | Medical Cannabis Savings & Education</title>
    <meta
     name="description"
     content="Bennabis Health partners with medical cannabis dispensaries and health providers to provide you medical cannabis discounts, cannabis education and more."
    />
   </Helmet>
   {referralModal === 'enable' && <Referral onClick={closeModal} />}
   <div className="homeTemplate">
    {bannerVisibility === 'enable' && <PromotionalBanner />}

    <div className="hone_banner_02">
     <img src={require('../assets/images/home-graphic-02.png')} alt="" />
     <div className="section_wrap">
      <div className="section">
       <span className="text-01">Join. Learn. Save.</span>
       <span className="text-02">
        Members get 15% off medical cannabis{' '}
        <span>from Network Dispensaries.</span>{' '}
       </span>
       <span className="action">
        <Link to="/enroll">Start Saving</Link>
       </span>
      </div>
     </div>
    </div>

    {/* discountPrograms */}
    <div className="section_wrap">
     <div className="section">
      <div className="discountPrograms">
       <div className="sectionTitle">
        <h1>Medical cannabis discounts and patient-centric programs</h1>
        <span>
         Successfully use medical cannabis to treat symptoms and side effects
         for a better quality of life.
        </span>
       </div>
       <div className="membershipCard">
        <div className="item">
         <div className="graphic">
          <img
           src={require('../assets/images/membership-type-01.jpg')}
           alt=""
          />
         </div>
         <div className="content">
          <h6>Considering medical cannabis?</h6>
          <p>Find out if it’s right for you.</p>
          <Link to="/education/curious">Start Learning</Link>
         </div>
        </div>
        <div className="item">
         <div className="graphic">
          <img
           src={require('../assets/images/membership-type-02.jpg')}
           alt=""
          />
         </div>
         <div className="content">
          <h6>Medical cannabis cardholders</h6>
          <p>Enhance your knowledge.</p>
          <Link to="/enroll">Start Saving</Link>
         </div>
        </div>
       </div>
       <div className="whyUs">
        <ul>
         <li>
          <Link to="/enroll">Join</Link> Join our membership program to save 15%
          on medical cannabis.
         </li>
         <li>
          <Link to="/education/getting-started">Learn</Link> Learn about using
          medical cannabis to treat symptoms and side effects.
         </li>
         <li>
          <Link to="/network-dispensary">Save</Link> Save on medical cannabis
          purchases at Network Dispensaries.
         </li>
        </ul>
       </div>
      </div>
     </div>
    </div>

    {/* statices */}
    <div className="section_wrap staticesWrap">
     <img
      className="banner"
      src={require('../assets/images/cannabis-bg.jpg')}
      alt=""
     />
     <div className="section">
      <div className="item">
       <span>$100</span>
       <strong>Average monthly member savings on product purchases.</strong>
      </div>
      <div className="item">
       <span>72+</span>
       <strong>
        Health conditions and side effects can be improved with medical
        cannabis.
       </strong>
      </div>
      <div className="item">
       <span>4.5M</span>
       <strong>
        The number of registered medical marijuana cardholders in the US.
       </strong>
      </div>
     </div>
    </div>

    {/* quickExplore */}
    <div className="section_wrap">
     <div className="quickExplore">
      <div className="item">
       <div className="card">
        <div className="graphic">
         <img src={require('../assets/images/explore-01.jpg')} alt="" />
        </div>
        <div className="cardInfo">
         <span>Discounts on Medical Cannabis</span>
         <Link to="/network-dispensary">Learn More</Link>
        </div>
       </div>
      </div>
      <div className="item">
       <div className="card">
        <div className="cardInfo">
         <span>Patient Community Support Groups </span>
         <Link to="/members">Learn More</Link>
        </div>
        <div className="graphic">
         <img src={require('../assets/images/explore-02.jpg')} alt="" />
        </div>
       </div>
      </div>
      <div className="item">
       <div className="card">
        <div className="graphic">
         <img src={require('../assets/images/explore-03.jpg')} alt="" />
        </div>
        <div className="cardInfo">
         <span>Medical Cannabis Education </span>
         <Link to="/education/ready">Learn More</Link>
        </div>
       </div>
      </div>
      <div className="item">
       <div className="card">
        <div className="cardInfo">
         <span>Workers’ Compensation Programs </span>
         <Link to="/workers-compensation">Learn More</Link>
        </div>
        <div className="graphic">
         <img src={require('../assets/images/explore-04.jpg')} alt="" />
        </div>
       </div>
      </div>
     </div>
    </div>

    {/* advertisement */}
    <div className="section_wrap cardRegistration">
     <div className="section">
      <div className="advertisement">
       <div className="graphic">
        <img src={require('../assets/images/advertisement.jpg')} alt="" />
       </div>
       <div className="advertisement_info">
        <h4>
         Become a medical cannabis patient.{' '}
         <span>We can help you get started.</span>
        </h4>
        <strong>100% online</strong>
        <div className="handler">
         <Link to="/membership-card">Get Your Card</Link>
        </div>
        <br />
       </div>
      </div>
     </div>
    </div>

    {/* PARTNERSHIPS */}
    <div className="section_wrap collaborative">
     <div className="collaborative_title">Collaborative Partnerships</div>
     <div className="partnerships">
      <span>
       <Link to="/membership-card">
        <img src={require('../assets/images/affiliates/leafwell.png')} alt="" />
       </Link>
      </span>
      <span>
       <Link to="/membership-card">
        <img src={require('../assets/images/affiliates/cmed.png')} alt="" />
       </Link>
      </span>
      <span>
       <a
        target="_blank"
        rel="noreferrer"
        href="https://www.theanswerpage.com/"
       >
        <img src={require('../assets/images/tap-logo.png')} alt="" />
       </a>
      </span>
     </div>
    </div>

    {/* EXPLOREFEATURES */}
    <div className="section_wrap">
     <div className="section">
      <div className="exploreFeatures">
       <Link to="/members">Member Benefits</Link>
       <Link to="/work-with-us/dispensaries">
        Join the <span>Dispensary Network</span>
       </Link>
       <Link to="/work-with-us/medical-providers">
        Education for <span>Medical Providers</span>
       </Link>
      </div>
      <div className="exploreFeatures">
       <Link to="/work-with-us/benefit-professionals">
        Information for <span>Brokers</span>
       </Link>
       <Link to="/work-with-us/health-benefit-plans">
        Options for <span>Health Benefit Plans</span>
       </Link>
       <Link to="/work-with-us/employees-and-dependents">
        Employees & <span>Dependents</span>
       </Link>
       <Link to="/work-with-us/employers">
        Options for <span>Employers</span>
       </Link>
      </div>
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default Home
