import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

// LOAD BANNER
const Banner = (props) => {
 const arrayPromo = props.promocode.split(',')
 const [promocode, strike_price, promotion_price] = arrayPromo

 return (
  <React.Fragment>
   <div
    className="section_wrap promotionalBanner"
    style={{
     backgroundImage: `url(${props.background})`,
     backgroundPosition: 'center top',
    }}
   >
    <div className="section">
     <div className="banner_text">
      <h6>{props.title}</h6>
      <div
       className="text_wrap"
       dangerouslySetInnerHTML={{ __html: props.content }}
      ></div>
     </div>
     {promocode === '-' || !promocode ? (
      <></>
     ) : (
      <div className="code_text">
       <span className="code">
        {promocode} <small>Apply Promo Code </small>
       </span>
       {(strike_price || promotion_price) && (
        <div className="pricing">
         <span>{!strike_price ? '' : `$${strike_price?.trim()}`}</span>
         <strong>
          {!promotion_price ? '' : `$${promotion_price?.trim()}`}
         </strong>
        </div>
       )}
      </div>
     )}
    </div>
   </div>
  </React.Fragment>
 )
}

const PromotionalBanner = () => {
 const [count, setCount] = React.useState(1)
 const [content, setContent] = React.useState([])

 // USEEFFECT LOAD BANNER DESCRIPTION
 React.useEffect(() => {
  const bannerContent = async () => {
   try {
    const respons = await fetch(
     `${process.env.REACT_APP_API_URL}/banners?status_id=1`,
    )
    const responsData = await respons.json()
    setContent(responsData.data)
    setCount(responsData.record)
   } catch (e) {
    console.log(e)
   }
  }
  bannerContent()
 }, [])

 return (
  <React.Fragment>
   {count === 1 ? (
    content.map((val) => {
     return (
      <div key={val.id}>
       <Banner
        title={val.title}
        content={val.content}
        promocode={val.promocode}
        background={val.background}
       />
      </div>
     )
    })
   ) : (
    <Carousel
     autoPlay={true}
     infiniteLoop={true}
     showThumbs={false}
     showArrows={false}
     showStatus={false}
     interval={6000}
    >
     {content.map((val) => {
      return (
       <div key={val.id}>
        <Banner
         title={val.title}
         content={val.content}
         promocode={val.promocode}
         background={val.background}
        />
       </div>
      )
     })}
    </Carousel>
   )}
  </React.Fragment>
 )
}

export default PromotionalBanner
