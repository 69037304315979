import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import axios from "axios";
import { Link } from 'react-router-dom';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const PatientQuestionnaire = () => {
  const [alertError, setAlertError] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [responseMessage, setResponseMessage] = useState();

  // VALIDATE & COMPARISON
  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Required Field.'),
    age: Yup.string()
      .required('Required Field.'),
    occupation: Yup.string()
      .required('Required Field.'),
    text1: Yup.string()
      .required('Required Field.'),
    text2: Yup.string()
      .required('Required Field.'),
  });

  // INITIAL VALUES OF INPUT
  const initialValues = {
    name: '',
    age: '',
    occupation: '',
    text1: '',
    text2: '',
    text3: '',
    text4: '',
    text5: '',
    text6: '',
    text7: '',
    text8: '',
    text9: '',
    text10: '',
    text11: '',
    text12: '',
    text13: '',
    text14: '',
    text15: '',
    text16: '',
    text17: '',
  };

  // FIELD VALIDATIOM ERROR MESSAGE
  const renderError = (message) => <div className="erroralert">{message}</div>;
  const onSubmit = (values) => {
    axios.post(`${process.env.REACT_APP_API_URL}patient-questionnaire.php`, values)
      .then(function (responseData) {
        const respons = responseData.data;
        if (respons.status === true && respons.message === 'success') {
          setAlertSuccess(true);
          setResponseMessage(respons.message);
        } else {
          setAlertError(true);
          setResponseMessage(respons.message);
        }
      });
  }; // ONSUBMIT

  // CHECK CONDITION AND DISPLAY DATA
  if (alertSuccess === true && responseMessage === 'success') {
    return (
      <React.Fragment>
        <div className="alertMessage">
          <div className="container">
            <div className="alert success">
              <div className="icon"><img src={require("../assets/images/alert-icon/success.svg").default} alt="" /></div>
              <div className="text"><p>Thank you! We appreciate that you have taken the time to complete our Patient Questionnaire.</p></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  } else if (alertError === true) {
    return (
      <React.Fragment>
        <div className="alertMessage">
          <div className="container">
            <div className="alert error">
              <div className="icon"><img src={require("../assets/images/alert-icon/error.svg").default} alt="" /></div>
              <div className="text">
                <p>{responseMessage}</p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <Helmet> <title>Patient Questionnaire</title> </Helmet>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            await onSubmit(values);
            resetForm();
          }} >
          {({ errors, touched, isValidating }) => (
            <div className="sectionWrap">
              <div className="sectionContainer">
                <Form>
                  <div className='patient_quest'>
                    <div className='acceptance'>
                      <h5>BENNABIS HEALTH PATIENT TESTIMONIAL QUESTIONNAIRE & SURVEY</h5>
                      <p>Please note that by filling out this form, you acknowledge and agree to our <Link to='/policies/terms-of-use' className='hyperlink'>Terms of Use</Link> including, but not limited to, the section governing Contributions.</p>
                      <p>Fill out this questionnaire if you would like to share your personal story with other current or potential medical cannabis patients.</p>
                      <p><em>When we share our stories, not only do we get a chance to reflect and see how far we’ve come on our journey, but we have the potential to help someone else gain hope in seeking a better quality of life and to learn from other patients’ experiences.</em></p>
                      <p><strong>Thank you for participating and being a part of the Bennabis Health community.</strong></p>
                    </div>
                    <div className='dataForm'>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">Patient Name *</label>
                          <Field type="text" name="name" maxLength="70" />
                          <ErrorMessage name="name" render={renderError} />
                        </div>
                        <div className="inputField">
                          <label htmlFor="title">What is your age? *</label>
                          <Field type="text" name="age" maxLength="70" />
                          <ErrorMessage name="age" render={renderError} />
                        </div>
                        <div className="inputField">
                          <label htmlFor="title">What is your occupation? *</label>
                          <Field type="text" name="occupation" maxLength="70" />
                          <ErrorMessage name="occupation" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">How and when did you first hear about or start using medical cannabis? *</label>
                          <Field component="textarea" name="text1" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text1" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">In what way(s) does cannabis help the symptoms of your condition and/or treat any side effects you experience from other medication you are on? *</label>
                          <Field component="textarea" name="text2" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text2" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">How were you treating the condition prior to using medical cannabis? </label>
                          <Field component="textarea" name="text3" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text3" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">Under the guidance of your physician, were you able to reduce or replace the amount of pharmaceutical medicine you take since starting medical cannabis?</label>
                          <Field type="text" name="text4" maxLength="150" />
                          <ErrorMessage name="text4" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">What types of cannabis do you ingest?</label>
                          <Field type="text" name="text5" maxLength="150" />
                          <ErrorMessage name="text5" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">What method(s) of Consumption do you use and what is your normal dosage?</label>
                          <Field type="text" name="text6" maxLength="150" />
                          <ErrorMessage name="text6" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">What are your experiences with trial and error getting used to medical cannabis?</label>
                          <Field component="textarea" name="text7" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text7" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">Do you ever find the need to alter your dose?  If so how ?</label>
                          <Field component="textarea" name="text8" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text8" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">Tell us a little about your hobbies and lifestyle?</label>
                          <Field component="textarea" name="text9" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text9" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">How has medical cannabis has affected your ability to work and/or participate in your normal daily activities.  How do you feel it has impacted your life or improved your quality of life? </label>
                          <Field component="textarea" name="text10" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text10" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">What tips can you suggest to other patients to eliminate some trial and error in learning what works for them? Do you have any tips or suggestions you would like to offer other patients with your condition(s)? Any advice to offer patients who are skeptical about medial cannabis ? </label>
                          <Field component="textarea" name="text11" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text11" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">Have you experienced any negative side effects since starting medical cannabis? If so, what have you done to combat those side effects?</label>
                          <Field component="textarea" name="text12" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text12" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">Do you use any other alternative treatments to treat your condition(s)? If so, which?</label>
                          <Field component="textarea" name="text13" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text13" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">How well does your healthcare provider understand medical cannabis?</label>
                          <Field component="textarea" name="text14" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text14" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">Do you think your healthcare provider could use more info on medical cannabis?  If so, please provide their name/contact #/address so we can reach out to help educate them. (Your name will remain anonymous)</label>
                          <Field component="textarea" name="text15" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text15" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">How was the process of getting your cannabis card?  What improvements should be made?</label>
                          <Field component="textarea" name="text16" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text16" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <label htmlFor="title">What topics would you like to be better educated on through Bennabis Health?</label>
                          <Field component="textarea" name="text17" maxLength="1000" rows="3"></Field>
                          <ErrorMessage name="text17" render={renderError} />
                        </div>
                      </div>
                      <div className="inputField">
                        <Field type="submit" value="Share My Experience with Bennabis Health" className="inputButton" />
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      </React.Fragment>
    )
  }
}

export default PatientQuestionnaire;