import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

const ViewPost = () => {
 const [error, setError] = useState(null)
 const [isLoaded, setIsLoaded] = useState(false)
 const { id } = useParams()
 const [postdetail, setPostdetail] = useState([])

 // GET REQUEST FOR MEDIA LIST
 const getData = async () => {
  try {
   const response = await fetch(`${process.env.REACT_APP_API_URL}/media/${id}`)
   setPostdetail(await response.json())
   setIsLoaded(true)
  } catch (error) {
   setIsLoaded(true)
   setError(error)
  }
 }

 useEffect(() => {
  getData()
 }, [])

 if (error) {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>Press Releases</title>{' '}
    </Helmet>
    <div className="showError">
     <span>Error Message: {error.message}</span>
    </div>
   </React.Fragment>
  )
 } else if (!isLoaded) {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>Press Releases</title>{' '}
    </Helmet>
    <div className="showLoading">
     <img src={require('../assets/images/dots.gif')} alt="" />
    </div>
   </React.Fragment>
  )
 } else {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>Press Releases</title>{' '}
    </Helmet>
    <div className="breadCrumbWrap">
     <div className="breadCrumb">
      <ul>
       <li>
        <Link to="/">
         <i className="fa fa-home" aria-hidden="true"></i>
        </Link>
       </li>
       <li>
        <Link to="/press-releases">Press Releases</Link>
       </li>
       <li>
        <Link to="">{postdetail.data.title}</Link>
       </li>
      </ul>
     </div>
    </div>
    <div className="pressReleases" style={{ padding: '2rem' }}>
     {postdetail.data.thumbnail !== null ? (
      <div className="container">
       <div className="graphic">
        <img src={postdetail.data.thumbnail} alt={postdetail.data.title} />
       </div>
       <div className="info">
        <div className="title">
         <h6>{postdetail.data.title}</h6>
        </div>
        <div
         className="description ql-editor"
         dangerouslySetInnerHTML={{ __html: postdetail.data.description }}
        ></div>
       </div>
       {postdetail.data.source !== null && (
        <>
         <div></div>
         <a
          style={{ width: 'fit-content' }}
          href={postdetail.data.source}
          target="_blank"
          rel="noreferrer"
         >
          <div className="description ql-editor">Source</div>
         </a>
        </>
       )}
      </div>
     ) : (
      <div className="innerContainer">
       <div className="info">
        <div className="title">
         <h6>{postdetail.data.title}</h6>
        </div>
        <div
         className="description ql-editor"
         dangerouslySetInnerHTML={{ __html: postdetail.data.description }}
        ></div>
       </div>
       {postdetail.data.source !== null && (
        <a
         style={{
          width: 'fit-content',
          display: 'inline-block',
          color: '#ff0000',
         }}
         href={postdetail.data.source}
         target="_blank"
         rel="noreferrer"
        >
         <div className="description ql-editor">Source</div>
        </a>
       )}
      </div>
     )}
    </div>
   </React.Fragment>
  )
 }
}

export default ViewPost
