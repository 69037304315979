import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { NavLink, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import SecondaryHeader from '../masterLayout/SecondaryHeader';

const Topic = () => {
  const { category, topic } = useParams();
  const [pageContent, setPageContent] = useState([]);
  const [topicCategory, settopicCategory] = useState([]);

  // CONVERT STRING TO TITLE CASE
  function titleCase(str) {
    return str.toLowerCase().split(' ').map(function (word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  // GET REQUEST FOR CATEGORY LIST
  const categoryList = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/categories?category_type_id=10&parent_id=28&status_id=1`);
      settopicCategory(await response.json());
    } catch (e) {
      console.log(e)
    }
  }

  // GET REQUEST FOR CATEGORY LIST DATA
  const getData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/topics?category_id=${topic}&status_id=1`);
      setPageContent(await response.json());
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getData()
    categoryList()
  }, [topic]);

  return (
    <React.Fragment>
      <Helmet> <title>{titleCase(category.replace(/-/g, " "))}</title> </Helmet>
      <SecondaryHeader page_title={category} />
      <div className='breadCrumbWrap'>
        <div className='breadCrumb'>
          <ul>
            <li><Link to='/'><i className="fa fa-home" aria-hidden="true"></i></Link></li>
            <li><Link to='/education/'>Cannabis Education</Link></li>
            <li><Link to={`/education/${category}`}>{category}</Link></li>
            <li><Link to=''>Topic Review</Link></li>
          </ul>
        </div>
      </div>
      <div className="sectionWrap">
        <div className="sectionContainer" id='cannabis-education'>
          <div className='topics'>
            <div className='category'>
              <ul>
                {topicCategory?.data?.sort((a,b) => a.category.toLowerCase().localeCompare(b.category.toLowerCase())).map((val) => {
                  return (
                    <li key={val.id}><NavLink to={`/education/${category}/${val.id}`}>{val.category}</NavLink></li>
                  )
                })}
              </ul>
            </div>
            <div className='pagedata'>
              {pageContent?.data?.map((data) => {
                return (
                  <div className='customer_review' key={data.id}>
                    <h6><a href={data.source} target='_blank' rel="noreferrer">{data.title}</a></h6>
                    <p>{data.description}</p>
                    <div className='source'><a href={data.source} target='_blank' rel="noreferrer" className='source_url'><i className="fa fa-angle-right" aria-hidden="true"></i> Read More</a></div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )

}

export default Topic;