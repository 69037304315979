import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SecondaryHeader from '../../masterLayout/SecondaryHeader'
import { ready } from './utilities/AccordionData'

// CUSTOME ACCORDION COMPONENT
const Accordion = ({ title, content }) => {
 const [isActive, setIsActive] = React.useState(false)
 return (
  <div className="accordion-item">
   <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
    <div dangerouslySetInnerHTML={{ __html: title }}></div>
    <div>{isActive ? '-' : '+'}</div>
   </div>
   {isActive && (
    <div
     className="accordion-content"
     dangerouslySetInnerHTML={{ __html: content }}
    ></div>
   )}
  </div>
 )
}

// BUILD TEMPLATE
const Ready = () => {
 return (
  <React.Fragment>
   <Helmet>
    {' '}
    <title>Ready</title>{' '}
   </Helmet>
   <SecondaryHeader page_title="Ready" />
   <div className="sectionWrap">
    <div className="sectionContainer" id="cannabis-education">
     <div className="pageContent ready">
      <div className="introduction">
       <h2>Ready to get started with Medical Cannabis?</h2>
       <p>
        <strong>
         Let us help you on your next steps in this journey. Learn more with
         Bennabis Health and join today.
        </strong>
       </p>
       <div className="content">
        <p>
         Bennabis Health offers reliable medical cannabis resources to patients
         who have been diagnosed with illnesses whose symptoms can be alleviated
         by medical cannabis.
        </p>
        <p>
         <Link to="/enroll">Join</Link> Bennabis Health today for your medical
         cannabis discounts, cannabis support groups, expert guidance, medical
         cannabis education, and more.
        </p>
        <p>
         Below we provide answers to some questions you may have if you are
         ready to begin your medical cannabis journey:
        </p>
       </div>
      </div>
     </div>
     <div className="accordion">
      {ready.map(({ title, content }) => (
       <Accordion title={title} content={content} />
      ))}
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default Ready
