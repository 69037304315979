import React, { useState, useEffect } from 'react'

const Referral = (props) => {

  const [content, setContent] = useState()

  // FUNCTION TO CHANGE STATE REFERRAL MODAL TO HOME COMPONENT
  function closeModal() {
    props.onClick(false);
  }

  // USEEFFECT LOAD REFERRAL DESCRIPTION
  useEffect(() => {
    const loadDescription = async () => {
      try {
        const respons = await fetch(`${process.env.REACT_APP_API_URL}/widget-customizes`);
        const responsData = await respons.json();
        setContent(responsData.data[0].description);
      } catch (e) {
        console.log(e)
      }
    }
    loadDescription();
  }, []);

  return (
    <React.Fragment>
      <div className='referralmodal'>
        <div className="referral_container">
          <a className='close' href="#" onClick={closeModal}><i className="fa fa-times" aria-hidden="true"></i></a>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Referral