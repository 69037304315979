import React from 'react'
import { Helmet } from "react-helmet";

const UnderConstruction = () => {
  return (
    <React.Fragment>
      <Helmet> <title>Frequently Asked Questions</title> </Helmet>
      <div className="underConstruction">
        <div className="pageheading"></div>
        <div className="graphic"><img src={require('../assets/images/uc.jpg')} alt="" /></div>
      </div>
    </React.Fragment>
  )
}

export default UnderConstruction