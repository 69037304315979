import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'

const ViewBlog = () => {
 const [error, setError] = useState(null)
 const [isLoaded, setIsLoaded] = useState(false)
 const { id } = useParams()
 const [postdetail, setPostdetail] = useState([])

 // GET REQUEST FOR BLOG DETAIL
 const getData = async () => {
  try {
   const response = await fetch(
    `${process.env.REACT_APP_API_URL}/blogs/${id}`,
    {
     method: 'GET',
     headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': '',
     },
    },
   )
   const data = await response.json()
   if (data.success) {
    setPostdetail(data.data)
    setIsLoaded(true)
   } else {
    throw new Error(data.message)
   }
  } catch (error) {
   setIsLoaded(true)
   setError(error)
  }
 }

 useEffect(() => {
  getData()
 }, [])

 if (error) {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>Blog Post</title>{' '}
    </Helmet>
    <div className="showError">
     <span>Error Message: {error.message}</span>
    </div>
   </React.Fragment>
  )
 } else if (!isLoaded) {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>Blog Post</title>{' '}
    </Helmet>
    <div className="showLoading">
     <img src={require('../assets/images/dots.gif')} alt="" />
    </div>
   </React.Fragment>
  )
 } else {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>Blog Post</title>{' '}
    </Helmet>
    <div className="breadCrumbWrap">
     <div className="breadCrumb">
      <ul>
       <li>
        <Link to="/">
         <i className="fa fa-home" aria-hidden="true"></i>
        </Link>
       </li>
       <li>
        <Link to="/blog">Blog</Link>
       </li>
       <li>
        <Link to="">{postdetail.title}</Link>
       </li>
      </ul>
     </div>
    </div>
    <div className="pressReleases" style={{ padding: '2rem' }}>
     {postdetail.thumbnail !== null ? (
      <div className="container">
       <div className="graphic">
        <img src={postdetail.thumbnail} alt={postdetail.title} />
       </div>
       <div className="info">
        <div className="title">
         <h6>{postdetail.title}</h6>
        </div>
        <div
         className="description ql-editor"
         dangerouslySetInnerHTML={{ __html: postdetail.description }}
        ></div>
       </div>
      </div>
     ) : (
      <div className="innerContainer">
       <div className="info">
        <div className="title">
         <h6>{postdetail.title}</h6>
        </div>
        <div
         className="description  ql-editor"
         dangerouslySetInnerHTML={{ __html: postdetail.description }}
        ></div>
       </div>
      </div>
     )}
    </div>
   </React.Fragment>
  )
 }
}

export function getThumbnail(thumbnail) {
 let thumb = thumbnail?.includes('http')
  ? thumbnail
  : `${process.env.REACT_APP_ADMIN_URL}assets/upload/${thumbnail}`
 if (thumbnail === null || thumbnail === undefined || thumbnail === '') {
  thumb = require('../assets/images/avatar.png')
 }
 return thumb
}

export default ViewBlog
