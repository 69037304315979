import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SecondaryHeader from '../masterLayout/SecondaryHeader'

const Blog = () => {
 const [error, setError] = useState(null)
 const [isLoaded, setIsLoaded] = useState(false)
 const [posts, setPosts] = useState([])

 // GET REQUEST FOR MEDIA LIST
 const getData = async () => {
  try {
   const response = await fetch(
    `${process.env.REACT_APP_API_URL}/blogs?status_id=1`,
    {
     method: 'GET',
     headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': '',
     },
    },
   )

   const data = await response.json()
   setPosts(data.data)
   setIsLoaded(true)
  } catch (error) {
   setError(error)
  }
 }

 useEffect(() => {
  getData()
 }, [])

 if (error) {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>Blog</title>{' '}
    </Helmet>
    <SecondaryHeader page_title="Blog" />
    <div className="showError">
     <span>Error Message: {error.message}</span>
    </div>
   </React.Fragment>
  )
 } else if (!isLoaded) {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>Blog</title>{' '}
    </Helmet>
    <SecondaryHeader page_title="Blog" />
    <div className="showLoading">
     <img src={require('../assets/images/dots.gif')} alt="" />
    </div>
   </React.Fragment>
  )
 } else {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>Blog</title>{' '}
    </Helmet>
    <SecondaryHeader page_title="Blog" />
    <div className="sectionWrap">
     <div className="sectionContainer">
      <div className="gridPost">
       {posts.map((val) => {
        return (
         <div className="item" key={val.id}>
          <Link to={`/blog/${val.id}`} style={{ height: '100%' }}>
           {val.thumbnail !== null ? (
            <div className="thumbnail">
             <img width="100%" src={val.thumbnail} alt="" />
            </div>
           ) : (
            <div style={{ height: '250px' }}></div>
           )}
           <div className="title">
            <p>{val.title}</p>
           </div>
          </Link>
         </div>
        )
       })}
      </div>
     </div>
    </div>
   </React.Fragment>
  )
 }
}

export default Blog
