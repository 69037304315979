import React, { useState, useEffect } from 'react'
import Header from './masterLayout/Header'
import Footer from './masterLayout/Footer'
import Navigation from './masterLayout/Navigation'
import LoadTemplate from './masterLayout/LoadTemplate'
import ReactGA from 'react-ga4'
import './assets/css/style.css'
import './assets/sass/style.scss'
import 'font-awesome/css/font-awesome.min.css'
import { withRouter } from 'react-router-dom'

function App() {
 const [data, SetData] = useState([])

 // FETCH VISITOR
 const getData = async () => {
  try {
   const response = await fetch('https://geolocation-db.com/json/')
   SetData(await response.json())
  } catch (e) {
   console.log(e)
  }
 }

 // POST DATA
 // axios.post(`${process.env.REACT_APP_API_URL}visiter.php`, { ...data })

 useEffect(() => {
  getData()
  ReactGA.initialize('UA-221299329-1')
 }, [])

 return (
  <React.Fragment>
   <div className="wrapper">
    <Header />
    <Navigation />
    <div className="templateBody" id="templateBody">
     <LoadTemplate />
    </div>
    <Footer />
   </div>
  </React.Fragment>
 )
}

export default withRouter(App)
