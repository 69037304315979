import React from 'react'
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import SecondaryHeader from '../../masterLayout/SecondaryHeader';
import accordionData from './utilities/AccordionData';

// CUSTOME ACCORDION COMPONENT
const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = React.useState(false);
  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div dangerouslySetInnerHTML={{ __html: title }}></div>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className="accordion-content" dangerouslySetInnerHTML={{ __html: content }}></div>}
    </div>
  );
};

// BUILD TEMPLATE
const GettingStart = () => {
  return (
    <React.Fragment>
      <Helmet> <title>Getting Started</title> </Helmet>
      <SecondaryHeader page_title="Getting Started" />
      <div className="sectionWrap">
        <div className="sectionContainer" id="cannabis-education">
          <div className="pageContent intro">
            <div className="introduction">
              <h2>Are you considering Medical Cannabis?</h2>
              <p><strong>Bennabis Health provides trusted medical cannabis resources to guide you on your journey! We know it’s complicated, so we are here to help.</strong></p>
              <div className="content">
                <p>Cannabis is essential to many patients, and that's why at Bennabis Health, we offer reliable medical cannabis resources to patients who have been diagnosed with illnesses whose symptoms can be alleviated by medical cannabis.</p>
                <p><Link to="/enroll">Join</Link> Bennabis Health today for your cannabis discount, cannabis support groups, expert guidance, and more.</p>
                <p>Below we provide answers to some key questions you may have if you are considering medical cannabis:</p>
              </div>
            </div>
          </div>
          <div className="accordion">
            {accordionData.map(({ title, content,  }) => (
              <Accordion title={title} content={content} />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default GettingStart