import React from 'react'
import { NavLink } from 'react-router-dom'

const NavBar = () => {
 return (
  <React.Fragment>
   <ul>
    <li>
     <NavLink to="/members">Member Benefits</NavLink>
     <ul>
      <li>
       <NavLink to="/members">Benefits of Joining</NavLink>
      </li>
      <li>
       <NavLink to="/network-dispensary">Dispensary Network</NavLink>
      </li>
      <li>
       <NavLink to="/membership-card">Medical Certifications</NavLink>
      </li>
      <li>
       <NavLink to="/patient-questionnaire">Patient Questionnaire</NavLink>
      </li>
      <li>
       <NavLink to="/promos">PROMOS or SPECIALS</NavLink>
      </li>
      <li>
       <a
        rel="noreferrer"
        href="https://member.bennabishealth.com/"
        target="_blank"
       >
        Member Login
       </a>
      </li>
     </ul>
    </li>
    <li>
     <NavLink to="/education">Cannabis Education</NavLink>
     <ul>
      <li>
       <NavLink to="/education/getting-started">Getting Started</NavLink>
      </li>
      <li>
       <NavLink to="/education/curious">Curious</NavLink>
      </li>
      <li>
       <NavLink to="/education/ready">Ready</NavLink>
      </li>
      <li>
       <NavLink to="/education/experienced">Experienced</NavLink>
      </li>
      <li>
       <NavLink to="/education/condition-specific">Condition Specific</NavLink>
      </li>
     </ul>
    </li>
    <li>
     <NavLink to="/workers-compensation">Workers' Compensation</NavLink>
    </li>
    <li>
     <NavLink to="/work-with-us">Partner with Us</NavLink>
     <ul>
      <li>
       <NavLink to="/work-with-us/dispensaries">Dispensaries</NavLink>
      </li>
      <li>
       <NavLink to="/work-with-us/benefit-professionals">
        Benefit Professionals
       </NavLink>
      </li>
      <li>
       <NavLink to="/work-with-us/health-benefit-plans">
        Health Benefit Plans
       </NavLink>
      </li>
      <li>
       <NavLink to="/work-with-us/medical-providers">Medical Providers</NavLink>
      </li>
     </ul>
    </li>
    <li>
     <NavLink to="/about-us">About Us</NavLink>
     <ul>
      <li>
       <NavLink to="/about-us">Introduction</NavLink>
      </li>
      <li>
       <NavLink to="/media/bennabis-health-news">Bennabis Health News</NavLink>
      </li>
      <li>
       <NavLink to="/press-releases">Press Releases</NavLink>
      </li>
     </ul>
    </li>
    <li>
     <NavLink to="/podcasts">Podcasts</NavLink>
    </li>
    <li>
     <NavLink to="/affiliates">Affiliates</NavLink>
    </li>
    <li>
     <NavLink to="/blog">Blog</NavLink>
    </li>
   </ul>
  </React.Fragment>
 )
}

export default NavBar
