import React from 'react'

const NetworkDispensaryList = ({ listDispensary }) => {
 return (
  <ul>
   {listDispensary?.data
    ?.sort((a, b) => a.title.localeCompare(b.title))
    ?.map((item, index) => (
     <li key={index}>
      <a rel="noreferrer" href={item.link} target="_blank">
       {item.title}
      </a>
     </li>
    ))}
  </ul>
 )
}
export default NetworkDispensaryList
