import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import SecondaryHeader from '../masterLayout/SecondaryHeader';

// AUDIO PALYER
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const Podcasts = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [podcastsListing, fetchListing] = useState([]);

  // GET REQUEST FOR PODCASTS
  const getData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/podcasts?status_id=1`);
      const result = await response.json();
      fetchListing(result.data);
      setIsLoaded(true);
    } catch (error) {
      setIsLoaded(true);
      setError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (error) {
    return (
      <React.Fragment>
        <Helmet>
          {' '}
          <title>Podcasts</title>{' '}
        </Helmet>
        <SecondaryHeader page_title="Podcasts" />
        <div className="showError">
          <span>Error Message: {error.message}</span>
        </div>
      </React.Fragment>
    );
  } else if (!isLoaded) {
    return (
      <React.Fragment>
        <Helmet>
          {' '}
          <title>Podcasts</title>{' '}
        </Helmet>
        <SecondaryHeader page_title="Podcasts" />
        <div className="showLoading">
          <img src={require('../assets/images/dots.gif')} alt="" />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Podcasts | Bennabis Health: Medical Cannabis Savings & Education
          </title>
          <meta
            name="description"
            content="Join Bennabis Health, and various voices in the cannabis industry, with our informative podcast episodes!"
          />
        </Helmet>
        <SecondaryHeader page_title="Podcasts" />
        <div className="podcast_wrapper">
          <div className="sectionContainer">
            <div className="browse_episodes">
              <h5>Browse Episodes</h5>
            </div>
            <div className="episodes_wrap">
              {podcastsListing.map((val) =>
                val.audio === null ? (
                  <React.Fragment key={val.id}>
                    <div className="episodes">
                      <div className="episodes_thumbnail">
                        <img
                          src={require('../assets/images/podcast-youtube.jpg')}
                          alt=""
                        />
                      </div>
                      <div className="episodes_info">
                        <small>{`${new Date(val.created_at).toLocaleString()}  // ${val.tags}`}</small>
                        <h6>
                          <i
                            className="fa fa-youtube-play"
                            aria-hidden="true"
                          ></i>{' '}
                          {val.title}
                        </h6>
                        <p>{val.description}</p>
                        <a
                          href={val.video_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={
                              require('../assets/images/alert-icon/success.svg')
                                .default
                            }
                            alt=""
                          />{' '}
                          Play Video
                        </a>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={val.id}>
                    <div className="episodes">
                      <div className="episodes_thumbnail">
                        <img
                          src={require('../assets/images/podcast-audio.jpg')}
                          alt=""
                        />
                      </div>
                      <div className="episodes_info">
                        <small>{`${new Date(val.created_at).toLocaleString()}  // ${val.tags}`}</small>
                        <h6>
                          <i
                            className="fa fa-headphones"
                            aria-hidden="true"
                          ></i>{' '}
                          {val.title}
                        </h6>
                        <p>{val.description}</p>
                        <div className="podcast_player" id="podcastPlayer">
                          <AudioPlayer
                            src={`${val.audio}`}
                            customVolumeControls={[]}
                            customAdditionalControls={[]}
                            showJumpControls={false}
                          />
                        </div>
                      </div>
                      <div
                        className="small_podcast_player"
                        id="devicepodcastPlayer"
                      >
                        <AudioPlayer
                          src={`${val.audio}`}
                          customVolumeControls={[]}
                          customAdditionalControls={[]}
                          showJumpControls={false}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default Podcasts;
