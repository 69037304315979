import React from 'react';

const SecondaryHeader = (props) => {
  return (
    <React.Fragment>
      <div className="secondaryHeader">
        <div className="pageTitle"><h1>{props.page_title.replace(/-/g," ")}</h1></div>
        <img src={require("../assets/images/source-banner.jpg")} alt="" className='banner' />
      </div>
    </React.Fragment>
  );
}

export default SecondaryHeader;