import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import axios from "axios";

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const NewsLetter = () => {
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [responseMessage, setResponseMessage] = useState();
 
  // VALIDATE & COMPARISON
  const validationSchema = Yup.object({
      name: Yup.string()
      .required('Name Is A Required Field.')
      .max(70, 'Must Be 70 Characters Or Less.')
      .matches(/^[aA-zZ\s]+$/, "Alphabets are allowed only."),
    email: Yup.string()
      .required('Email Is A Required Field.')
      .email('Email Is Invalid'),
  });

  // INITIAL VALUES OF INPUT
  const initialValues = {
    email: '',
      name:''
  };

  // FIELD VALIDATIOM ERROR MESSAGE
  const renderError = (message) => <div className="erroralert">{message}</div>;

  const onSubmit = (values) => {
    axios.post(`${process.env.REACT_APP_API_URL}/newsletter-subscriptions`, values)
      .then(function (responseData) {
        const respons = responseData.data;
        if (respons.status === true && respons.message === 'success') {
          setAlertSuccess(true);
          setResponseMessage(respons.message);
           
        } else {
          setAlertError(true);
          setResponseMessage(respons.message);
        }
      });
  }; // ONSUBMIT

  // CHECK CONDITION AND DISPLAY DATA
  if (alertSuccess === true) {
    return (
      <React.Fragment>
        <div className="alertMessage">
          <div className="container">
            <div className="alert success">
              <div className="icon"><img src={require("../assets/images/alert-icon/success.svg").default} alt="" /></div>
              <div className="text"><p>Thank you for filling out our sign up form. We are glad that you joined us. Please come back and see us often.</p></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  } else if (alertError === true) {
    return (
      <React.Fragment>
        <div className="alertMessage">
          <div className="container">
            <div className="alert error">
              <div className="icon"><img src={require("../assets/images/alert-icon/error.svg").default} alt="" /></div>
              <div className="text">
                <p>{responseMessage}</p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <Helmet> <title>Sign Up For Bennabis Health E-Mail Updates</title> </Helmet>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            await onSubmit({...values, status_id:1});
            resetForm();
          }} >
          {({ errors, touched, isValidating }) => (
            <div className="sectionWrap">
              <div className="sectionContainer">
                <Form>
                  <div className='newsletterSubscription'>
                    <div className="pageTitle"><h4>Sign Up For Bennabis Health E-Mail Updates</h4></div>
                    <div className='dataForm'>
                      <div className="data_row">
                        <div className="inputField">
                          <Field type="text" name="email" placeholder="Ex: sincere@april.biz" autoComplete='off' />
                          <ErrorMessage name="email" render={renderError} />
                        </div>
                      </div>
                      <div className="data_row">
                        <div className="inputField">
                          <Field type="text" name="name" maxLength="70" placeholder="Ex: Leanne Graham" autoComplete='off' />
                          <ErrorMessage name="name" render={renderError} />
                        </div>
                      </div>
                      <div className="inputField">
                        <button type='submit'><img src={require("../assets/images/registration.svg").default} alt="" /> Submit</button>
                       </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      </React.Fragment>
    )
  }
}

export default NewsLetter;