import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import SecondaryHeader from '../masterLayout/SecondaryHeader'
import { Link } from 'react-router-dom'
import axios from 'axios'

const Contact = () => {
 const [alertSuccess, setAlertSuccess] = useState(false)
 const [alertError, setAlertError] = useState(false)
 const [responseMessage, setResponseMessage] = useState()
 const [inputValue, setInputValue] = useState({})

 useEffect(() => {
  if (inputValue.contact_method === 'Email') {
   const { contact_time, ...rest } = inputValue
   setInputValue(rest)
  }
 }, [inputValue.contact_method])

 useEffect(() => {
  if (inputValue.question_topic !== 'Group Benefits') {
   const { sponsor_name, ...rest } = inputValue
   setInputValue(rest)
  }
 }, [inputValue.question_topic])

 useEffect(() => {
  const phoneText = document.getElementById('mobile')
  if (inputValue.contact_method !== 'Email') {
   phoneText?.setAttribute('required', 'required')
  } else {
   phoneText?.removeAttribute('required')
  }
 }, [inputValue.contact_method])

 const inputEvent = (event) => {
  const name = event.target.name
  const value = event.target.value

  setInputValue((values) => ({ ...values, [name]: value }))
 }

 const onSubmit = (event) => {
  event.preventDefault()
  axios
   .post(`${process.env.REACT_APP_API_URL}/contact`, inputValue)
   .then(function (responseData) {
    const respons = responseData.data
    if (respons.success === true && respons.message === 'success') {
     setAlertSuccess(true)
     setResponseMessage(respons.message)
    } else {
     setAlertError(true)
     setResponseMessage(respons.message)
    }
   })
 }

 // CHECK CONDITION AND DISPLAY DATA
 if (alertSuccess === true) {
  return (
   <React.Fragment>
    <div className="alertMessage">
     <div className="container">
      <div className="alert success">
       <div className="icon">
        <img
         src={require('../assets/images/alert-icon/success.svg').default}
         alt=""
        />
       </div>
       <div className="text">
        <p>Thank you for your inquiry! We will respond as soon as possible.</p>
       </div>
      </div>
     </div>
    </div>
   </React.Fragment>
  )
 } else if (alertError === true) {
  return (
   <React.Fragment>
    <div className="alertMessage">
     <div className="container">
      <div className="alert error">
       <div className="icon">
        <img
         src={require('../assets/images/alert-icon/error.svg').default}
         alt=""
        />
       </div>
       <div className="text">
        <p>{responseMessage}</p>
       </div>
      </div>
     </div>
    </div>
   </React.Fragment>
  )
 } else {
  return (
   <React.Fragment>
    <Helmet>
     <title>
      Contact | Bennabis Health: Medical Cannabis Savings & Education
     </title>
     <meta
      name="description"
      content="Many answers to common questions may be found on our FAQ page. If you still need assistance, please do not hesitate to reach out to us with questions."
     />
    </Helmet>
    <SecondaryHeader page_title="Contact Us" />
    <div className="sectionWrap">
     <div className="sectionContainer">
      <div className="contact">
       <div className="pageHeader">
        <h4>Get In Touch With Us</h4>
        <p>
         Many answers to common questions may be found on our{' '}
         <Link to="faqs">FAQ</Link> Page. If you still can’t find your answer,
         feel free to contact us.
        </p>
       </div>
       <div className="content_section">
        <form onSubmit={onSubmit}>
         <div className="dataForm">
          <p style={{ marginBottom: '8px' }}>I have a question about:</p>
          <div className="data_row" style={{ marginBottom: '8px' }}>
           <div className="radio_row">
            <input
             type="radio"
             name="question_topic"
             id="benefit"
             value="Group Benefits"
             onChange={inputEvent}
             required
            />
            <label htmlFor="benefit">Group Benefits</label>
           </div>
           <div className="radio_row">
            <input
             type="radio"
             name="question_topic"
             id="individual"
             value="Individual Membership"
             onChange={inputEvent}
             required
            />
            <label htmlFor="individual">Individual Membership</label>
           </div>
           <div className="radio_row">
            <input
             type="radio"
             name="question_topic"
             id="other"
             value="Other"
             onChange={inputEvent}
             required
            />
            <label htmlFor="other">Other</label>
           </div>
          </div>
          {inputValue?.question_topic === 'Group Benefits' && (
           <div className="data_row">
            <div className="inputField">
             <label htmlFor="sponsor">
              Employer/Group Benefit Sponsor (if applicable)
             </label>
             <input
              type="text"
              name="sponsor_name"
              required
              onChange={inputEvent}
             />
            </div>
           </div>
          )}
          <div className="data_row">
           <div className="inputField">
            <label htmlFor="title">Name *</label>
            <input
             type="text"
             name="name"
             onChange={inputEvent}
             maxLength="150"
             required
            />
            <small className="help_text">Max 150 Characters Allowed</small>
           </div>
           <div className="inputField">
            <label htmlFor="title">Email Address *</label>
            <input
             type="text"
             name="email"
             onChange={inputEvent}
             maxLength="150"
             required
            />
            <small className="help_text">Max 150 Characters Allowed</small>
           </div>
          </div>
          <div className="data_row">
           <div className="inputField">
            <label htmlFor="title">Mobile</label>
            <input
             id="mobile"
             type="text"
             name="mobile"
             onChange={inputEvent}
             maxLength="10"
            />
           </div>
           <div className="inputField">
            <label htmlFor="title">How Did You Hear About Us ?</label>
            <div className="selectbox">
             <select name="marketing_source" onChange={inputEvent}>
              <option value="Search Engine">Search Engine</option>
              <option value="My Employer/Union">My Employer/Union</option>
              <option value="Word of Mouth">Word of Mouth</option>
              <option value="Social (Facebook, LinkedIn, Twitter, etc.)">
               Social (Facebook, LinkedIn, Twitter, etc.)
              </option>
              <option value="In-Person Events">In-Person Events</option>
              <option value="Friends or Co-worker">Friends or Co-worker</option>
              <option value="Other">Other</option>
             </select>
            </div>
           </div>
          </div>
          <div className="data_form">
           <p style={{ marginBottom: '8px' }}>I prefer to be contacted by:</p>
           <div className="data_row" style={{ marginBottom: '8px' }}>
            <div className="radio_row">
             <input
              type="radio"
              name="contact_method"
              id="email"
              value="Email"
              onChange={inputEvent}
              required
             />
             <label htmlFor="email">Email</label>
            </div>
            <div className="radio_row">
             <input
              type="radio"
              name="contact_method"
              id="phone"
              value="Phone"
              onChange={inputEvent}
              required
             />
             <label htmlFor="phone">Phone</label>
            </div>
            <div className="radio_row">
             <input
              type="radio"
              name="contact_method"
              id="either"
              value="Either"
              onChange={inputEvent}
              required
             />
             <label htmlFor="either">Either</label>
            </div>
           </div>
          </div>
          {inputValue?.contact_method !== 'Email' && (
           <div className="data_form">
            <div className="inputField">
             <label htmlFor="title">
              Best days/times to call me (if applicable)
             </label>
             <input
              type="text"
              name="contact_time"
              onChange={inputEvent}
              required
              maxLength="150"
             />
             <small className="help_text">Max 150 Characters Allowed</small>
            </div>
           </div>
          )}
          <div className="data_row">
           <div className="inputField">
            <label htmlFor="title">Message *</label>
            <textarea
             name="message"
             onChange={inputEvent}
             rows="5"
             required
             maxLength="250"
            ></textarea>
            <small className="help_text">Max 250 Characters Allowed</small>
           </div>
          </div>
          <div className="inputField">
           <input type="submit" value="Send Message" className="inputButton" />
          </div>
         </div>
        </form>
        <div className="contact_type">
         <div className="item_box">
          <small>Email</small>
          <span>
           <Link to="mailto:team@bennabishealth.com">
            team@bennabishealth.com
           </Link>
          </span>
         </div>
         <div className="item_box">
          <small>Explore</small>
          <ul>
           <li>
            <Link to="/work-with-us/health-benefit-plans">
             Health Benefit Plans
            </Link>
           </li>
           <li>
            <Link to="/work-with-us/dispensaries">Dispensaries</Link>
           </li>
           <li>
            <Link to="/media/bennabis-health-news">Bennabis Health News</Link>
           </li>
           <li>
            <Link to="/work-with-us/webinars">Webinars</Link>
           </li>
           <li>
            <Link to="/podcasts">Podcasts</Link>
           </li>
          </ul>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </React.Fragment>
  )
 }
}

export default Contact
