import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SecondaryHeader from '../../masterLayout/SecondaryHeader'
import { curious } from './utilities/AccordionData'

// CUSTOME ACCORDION COMPONENT
const Accordion = ({ title, content }) => {
 const [isActive, setIsActive] = React.useState(false)
 return (
  <div className="accordion-item">
   <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
    <div dangerouslySetInnerHTML={{ __html: title }}></div>
    <div>{isActive ? '-' : '+'}</div>
   </div>
   {isActive && (
    <div
     className="accordion-content"
     dangerouslySetInnerHTML={{ __html: content }}
    ></div>
   )}
  </div>
 )
}

// BUILD TEMPLATE
const Curious = () => {
 return (
  <React.Fragment>
   <Helmet>
    {' '}
    <title>Curious</title>{' '}
   </Helmet>
   <SecondaryHeader page_title="Curious" />
   <div className="sectionWrap">
    <div className="sectionContainer" id="cannabis-education">
     <div className="pageContent curious">
      <div className="introduction">
       <h2>Are you Curious about Medical Cannabis?</h2>
       <p>
        <strong>
         Learn more about cannabis as medicine from our trusted medical cannabis
         resources!
        </strong>
       </p>
       <div className="content">
        <p>
         Cannabis is essential to many patients, and that's why at Bennabis
         Health, we offer reliable services to patients who have been diagnosed
         with illnesses whose symptoms can be alleviated by medical cannabis. We
         are proud to have a team comprised of some of the most compassionate
         and knowledgeable cannabis professionals across the globe.
        </p>
        <p>
         <Link to="/enroll">Join</Link> Bennabis Health today for cannabis
         support groups, expert guidance, medical marijuana education, and more.
        </p>
        <p>
         Below we provide answers to some questions you may have if you are
         curious about medical marijuana:
        </p>
       </div>
      </div>
     </div>
     <div className="accordion">
      {curious.map(({ title, content }, index) => (
       <Accordion title={title} content={content} />
      ))}
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default Curious
