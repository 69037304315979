import React from 'react'
import { Helmet } from 'react-helmet'

function Affiliates() {
 return (
  <React.Fragment>
   <Helmet>
    <title>
     Affiliates | Bennabis Health: Medical Cannabis Discounts & Education
    </title>
    <meta
     name="description"
     content="View Bennabis Health affiliates and find even more discounts and savings on various cannabis related products and services."
    />
   </Helmet>
   <div className="sectionWrap affiliates">
    <img
     src={require('../assets/images/graphic-01.png')}
     alt=""
     className="backgroundSource"
    />
    <div className="sectionContainer">
     <div className="section nuggmd">
      <div className="graphic" style={
       {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '50px 10px',
        backgroundColor: 'white',
       }
      }>
       <img src={require('../assets/images/affiliates/cmed.png')} alt="" width="75%" />
       <img src={require('../assets/images/affiliates/leafwell.png')} alt="" width="75%" />
      </div>
      <div className="sectionDetail">
       <span className="heading-01">MEDICAL CANNABIS EVALUATIONS</span>
       <span className="heading-02">
        Get your Medical Cannabis certification at a discount through our
        affiliates.
       </span>
       <p>
        <a href="/membership-card">
         <strong>Speak with a medical cannabis expert today!</strong>
        </a>
       </p>
      </div>
     </div>
     <div className="section nuggmd">
      <div className="sectionDetail">
       <span className="heading-01">CANNABIS NURSE HOTLINE</span>
       <span className="heading-02">
        Speak with a trained cannabis nurse for guidance.
       </span>
       <p>
        <a href="https://leaf411.org/">
         <strong>Schedule your appointment today!</strong>
        </a>
       </p>
       <p>
        Their mission is to provide education and directional support on the
        safe use of legal cannabis.
       </p>
      </div>
      <div className="graphic">
       <img src={require('../assets/images/graphic-leaf411.png')} alt="" />
      </div>
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default Affiliates
