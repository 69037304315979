export const medicalCannabisStates = [
 'Washington',
 'Montana',
 'North Dakota',
 'South Dakota',
 'Minnesota',
 'Iowa',
 'Michigan',
 'Ohio',
 'Pennsylvania',
 'Vermont',
 'New Hampshire',
 'Maine',
 'Rhode Island',
 'Illinois',
 'Missouri',
 'Connecticut',
 'Maryland',
 'West Virginia',
 'Virginia',
 'Georgia',
 'Florida',
 'Alabama',
 'Mississippi',
 'Louisiana',
 'Arkansas',
 'Oklahoma',
 'Colorado',
 'Utah',
 'Nevada',
 'Arizona',
 'California',
 'Alaska',
 'Oregon',
 'Hawaii',
]
