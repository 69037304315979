import React from 'react'
import { Helmet } from 'react-helmet'

const Promos = () => {
 return (
  <React.Fragment>
   <Helmet>
    {' '}
    <title>Promos Or Specials</title>{' '}
   </Helmet>
   <div className="sectionWrap promosTemplate">
    <div className="sectionContainer">
     <div className="promoSection">
      <div className="item status">
       <small className="active">
        <img
         src={require('../assets/images/success-white.svg').default}
         alt=""
        />{' '}
        Active Currently
       </small>
      </div>
      <div className="item heading">
       <h4>Refer new members and save more!</h4>
       <p>
        Save on your membership renewal when you refer your friends to Bennabis
        Health.
       </p>
      </div>
      <div className="item">
       <ul>
        <li>1 Referral = 25% savings off Membership renewal*</li>
        <li>3 Referrals = 1 year free*</li>
       </ul>
      </div>
      <div className="item">
       <p>
        <strong>
         *Savings are provided off the referring member’s renewal for the
         following year. Existing members and newly enrolled members are
         eligible for Referral program savings.
        </strong>
       </p>
      </div>
      <div className="item condition">
       <ul>
        <li>
         All promotions require payment in full to complete the enrollment no
         later than December 31st, 2024.
        </li>
        <li>
         Referral must become a paid member by deadline for incentives to apply.
        </li>
        <li>
         Referral program applies to both Basic and Premium level Memberships.
        </li>
        <li>
         New registrations must include the referring member’s name in the “How
         did you hear about us” box to be eligible for the Referral program.
        </li>
       </ul>
      </div>
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default Promos
