import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const Error404 = () => {
 return (
  <React.Fragment>
   <Helmet>
    {' '}
    <title>404 Error - Page Not Found</title>{' '}
   </Helmet>
   <div className="sectionWrap">
    <div className="sectionContainer errorLayout">
     <div className="container">
      <h4>404 Error Page</h4>
      <p>Sorry, This page doesn't exist</p>
      <Link to="/" replace>
       Go Back
      </Link>
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default Error404
