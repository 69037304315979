import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import SecondaryHeader from '../masterLayout/SecondaryHeader'

const Faqs = () => {
 const { id = 12 } = useParams()
 const [allFaqs, setAllFaqs] = useState([])
 const [faqs, faqListing] = useState([])

 // GET REQUEST FOR FAQs CATEGORIES
 const faqCategories = async () => {
  try {
   const response = await fetch(
    `${process.env.REACT_APP_API_URL}/categories?category_type_id=2&status_id=1`,
   )
   setAllFaqs(await response.json())
  } catch (e) {
   console.log(e)
  }
 }

 // GET FAQs CATEGORY LISTING
 const getData = async () => {
  try {
   const response = await fetch(
    `${process.env.REACT_APP_API_URL}/faqs?category_id=${id}&status_id=1`,
   )
   faqListing(await response.json())
  } catch (e) {
   console.log(e)
  }
 }

 useEffect(() => {
  faqCategories()
  getData()
 }, [id])

 useEffect(() => {
  const smoothHash = window.location.hash
  if (smoothHash) {
   const element = document.querySelector(smoothHash)
   if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' })
   }
  }
 }, [faqs])

 return (
  <React.Fragment>
   <Helmet>
    <title>FAQ | Bennabis Health: Medical Cannabis Savings & Education</title>
    <meta
     name="description"
     content="Learn about Bennabis Health frequently asked questions regarding medical marijuana membership, brokers, health benefit plans, dispensary information and more."
    />
   </Helmet>
   <SecondaryHeader page_title="Help Center" />
   <div className="sectionWrap">
    <div className="sectionContainer">
     <div className="faqsWrap">
      <div className="faqs_categories">
       <ul>
        {allFaqs?.data?.map((val) => {
         return (
          <li key={val.id}>
           <NavLink to={`/faqs/${val.id}`}>{val.category}</NavLink>
          </li>
         )
        })}
       </ul>
      </div>
      <div className="FaqListing">
       {faqs?.data?.map((val) => {
        return (
         <div
          className="faqs"
          key={val.id}
          id={val.id === 8 ? 'smooth' : undefined}
         >
          <h6>{val?.question}</h6>
          <div
           className="answer ql-editor"
           dangerouslySetInnerHTML={{ __html: val?.answer }}
          ></div>
         </div>
        )
       })}
      </div>
     </div>
    </div>
   </div>
  </React.Fragment>
 )
}

export default Faqs
