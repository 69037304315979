import React from 'react'
import { Helmet } from "react-helmet";

const UnderMaintenance = () => {
  return (
    <React.Fragment>
      <Helmet> <title>Become A Member</title> </Helmet>
      <div className="sectionWrap">
        <div className="sectionContainer">
          <center> <p className='text-warning'>Member registration will be resume shortly.</p></center>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UnderMaintenance