import React from 'react'

import { Stack, Typography } from '@mui/material'
import { ReactComponent as IconAmerican } from '../../assets/images/amex.svg'
import { ReactComponent as IconDiscover } from '../../assets/images/discover.svg'
import { ReactComponent as IconMaster } from '../../assets/images/mastercard.svg'
import { ReactComponent as IconVisa } from '../../assets/images/visa.svg'

const PaymentCards = ({ position = 'center' }) => {
 return (
  <Stack mt={1}>
   <Typography textAlign={position} variant="subtitle1">
    We accept credit cards:
   </Typography>
   <Stack direction="row" justifyContent={position} gap={1} flexWrap="wrap">
    <IconVisa />
    <IconMaster />
    <IconAmerican />
    <IconDiscover />
   </Stack>
  </Stack>
 )
}
export default PaymentCards
