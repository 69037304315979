import React from 'react'
import { Link } from 'react-router-dom'

const EnrollmentMessage = () => {
 return (
  <div style={{
    textAlign: 'center',
    margin: '2rem auto',
    maxWidth: '800px',
    padding: '1rem'
  }}>
   <p>Enrollment is temporarily unavailable. We appreciate your interest in Bennabis Health and sincerely apologize for
    the inconvenience. Please check back later. For additional information, please
    {' '}
    <Link to="/contact" style={{
     textDecoration: 'underline',
    }}>
     contact us
    </Link>.</p>
  </div>
 )
}

export default EnrollmentMessage
