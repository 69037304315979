import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import SecondaryHeader from '../../masterLayout/SecondaryHeader';

const Experienced = () => {
  const [pageContent, setPageContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  // GET REQUEST FOR PAGE CONTENT
  const getData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/sections?slug=experienced&status_id=1`);
      setPageContent(await response.json());
      setTimeout(() => {
        setIsLoading(false)
      },0)
    } catch (e) {
      console.log(e)
    }
  }

  // USE EFFECT
  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <Helmet> <title>Experienced</title> </Helmet>
      <SecondaryHeader page_title='experienced' />
      <div className="sectionWrap">
        <div className="sectionContainer" id='cannabis-education'>
          {isLoading && <div className='showLoading'><img src={require("../../assets/images/dots.gif")} alt="" /></div>}
          {!pageContent?.data?.length && !isLoading && <div style={{textAlign:"center"}}> <p style={{fontSize:"20px"}}>Temporary not available :(</p> <p style={{fontSize:"20px"}}> please check later</p></div>}
          {pageContent?.data?.map((val) => {
            return (
              <div className={`pageContent experienced`} dangerouslySetInnerHTML={{ __html: val.description }} key={val.id}></div>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Experienced