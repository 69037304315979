import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import { sub } from 'date-fns/fp'
import { ReactComponent as IconSuccess } from '../../assets/images/alert-icon/success.svg'
import { ReactComponent as IconError } from '../../assets/images/alert-icon/error.svg'

import {
 ErrorMessage,
 Field,
 Form,
 Formik,
 useField,
 useFormikContext,
} from 'formik'
import * as Yup from 'yup'
import { CircularProgress } from '@mui/material'

// DATEPICKER
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import SummaryDialog from './SummaryDialog'
import PaymentCards from './PaymentCards'
// CUSTOM DATEPICKERFIELD
const DatePickerField = ({ ...props }) => {
 const { setFieldValue } = useFormikContext()
 const [field] = useField(props)
 return (
  <DatePicker
   {...field}
   {...props}
   selected={(field.value && new Date(field.value)) || null}
   onChange={(val) => {
    setFieldValue(field.name, val)
   }}
   format="MM-DD-YYYY"
   placeholderText="Ex: MM/DD/YYYY"
   autoComplete="off"
   maxDate={new Date()}
   yearDropdownItemNumber={100}
   showYearDropdown={true}
   scrollableYearDropdown={true}
   onKeyDown={(e) => {
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
     this.setState({ value: e.target.value })
    }
   }}
  />
 )
}

const BasicEnrollment = () => {
 const [alertError] = useState(false)
 const [responseMessage] = useState()
 const [marketingsource, setMarketingsource] = useState('10')
 const [token, setToken] = useState('')
 const [isLoading, setIsLoading] = useState(false)
 const [errorForm, setErrorForm] = useState('')
 const history = useHistory()
 const [promocodeText, setPromocodeText] = useState('')
 const [discount, setDiscount] = useState(null)
 const [open, setOpen] = useState(false)
 const params = new URLSearchParams(document.location.search)
 const [isExecute, setIsExecute] = useState(false)

 const handleOpen = () => setOpen(true)
 const handleClose = () => setOpen(false)

 const getPromocode = async () => {
  const fetchData = await fetch(
   `${process.env.REACT_APP_API_URL}/subscription-plans/calculate-price?promocode=${promocodeText}&subscription_plan_id=1`,
  )
  const data = await fetchData.json()
  setDiscount(data.data)
 }

 // VALIDATE & COMPARISON
 const validationSchema = Yup.object({
  firstName: Yup.string()
   .required('First Name Is A Required Field.')
   .max(15, 'Must Be 15 Characters Or Less.')
   .matches(/^[aA-zZ\s]+$/, 'Please use letters only. Ex: John.'),
  lastName: Yup.string()
   .required('Last Name Is A Required Field.')
   .max(15, 'Must Be 15 Characters Or Less.')
   .matches(/^[aA-zZ\s]+$/, 'Please use letters only. Ex: Smith.'),
  dob: Yup.date()
   .nullable()
   .required('DOB Is A Required Field.')
   .max(
    sub({ years: 18 }, new Date()),
    'Must be 18 years of age to create a Bennabis Health account',
   ),
  email: Yup.string()
   .required('Email Is A Required Field.')
   .email('Email Is Invalid'),
  password: Yup.string()
   .matches(/^.*(?=.{8,}).*$/, 'Enter at least 8 characters')
   .matches(/^.*(?=.*\d).*$/, 'Enter at least 1 number')
   .matches(/^.*((?=.*[a-z]){1}).*$/, 'Enter at least 1 lowercase character')
   .matches(/^.*((?=.*[A-Z]){1}).*$/, 'Enter at least 1 uppercase character')
   .matches(
    /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1}).*$/,
    'Enter at least 1 special character',
   )
   .trim()
   .required('Required Field'),
  password_confirmation: Yup.string()
   .required('Please retype correct password')
   .oneOf([Yup.ref('password')], "Your password doesn't match"),
  mobile: Yup.string()
   .min(10, 'Invalid Mobile, Must be 10 digit number.')
   .max(10, 'Maximum 10 digit allowed.')
   .matches(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    'Must be digit only.',
   ),
  address_01: Yup.string()
   .max(70, 'Must Be 70 Characters Or Less.')
   .required('Address Is A Required Field.'),
  city: Yup.string()
   .required('City Is A Required Field.')
   .matches(/^[aA-zZ\s]+$/, 'Alphabets are allowed only.'),
  state: Yup.string()
   .required('State Is A Required Field.')
   .matches(/^[aA-zZ\s]+$/, 'Alphabets are allowed only.'),
  zip: Yup.string()
   .required('Zip Is A Required Field.')
   .matches(/^\d+$/, 'Must be digit only.')
   .min(5, 'Zip code must be minimum 5 digit.')
   .max(10, 'Maximum 10 digit allowed.'),
  acceptance_01: Yup.boolean().oneOf(
   [true],
   'You must accept the terms and conditions',
  ),
 })

 // INITIAL VALUES OF INPUT
 const initialValues = {
  acceptance_01: false,
  firstName: '',
  lastName: '',
  dob: '',
  email: '',
  mobile: '',
  password: '',
  password_confirmation: '',
  address_01: '',
  address_02: '',
  city: '',
  state: '',
  zip: '',
  promoCode: '',
  marketing_source_explanation: '',
  web_marketing_id: 'null',
 }

 useEffect(() => {
  if (params.get('promocode') !== null) {
   setPromocodeText(params.get('promocode'))
   setIsExecute(true)
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [])

 useEffect(() => {
  if (isExecute) getPromocode()
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [isExecute])

 // FIELD VALIDATIOM ERROR MESSAGE
 const renderError = (message) => <div className="erroralert">{message}</div>

 // ONSUBMIT
 const onSubmit = (values) => {
  setIsLoading(true)
  setErrorForm('')
  axios
   .post(`${process.env.REACT_APP_API_URL}/enrollments`, {
    name: `${values.firstName} ${values.lastName}`,
    subscription_plan_id: 1,
    password: values.password,
    password_confirmation: values.password_confirmation,
    email: values.email,
    mobile: values.mobile,
    address_01: values.address_01,
    address_02: values.address_02,
    city: values.city,
    state: values.state,
    zip: values.zip,
    bad_dob: values.dob,
    promocode: promocodeText,
    web_marketing_id: Number(marketingsource),
    source_explanation: values.marketing_source_explanation,
   })
   .then((el) => {
    setToken(el?.data.data.token)
    setTimeout(() => {
     setIsLoading(false)
     if (!el?.data.data.token) {
      history.push('/enroll/success')
     } else {
      handlePayment()
     }
    }, 2000)
   })
   .catch((err) => {
    setIsLoading(false)
    if (err.response && err.response.data) {
     const errorMessage = err.response.data.message
     if (errorMessage.includes('has an existing account')) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(errorMessage, 'text/html')
      const linkElement = doc.querySelector('a')

      const linkText = linkElement.textContent
      const linkUrl = linkElement.getAttribute('href')
      const updatedMessage = errorMessage.replace(
       linkText,
       `<a href="${linkUrl}">${linkText}</a>`,
      )

      setErrorForm(<p dangerouslySetInnerHTML={{ __html: updatedMessage }} />)
     }
    }
   })
 }

 const CollectSource = (props) => {
  return (
   <React.Fragment>
    <div
     className="data_row"
     style={{ display: 'flex', justifyContent: 'flex-start' }}
    >
     <div className="inputField" style={{ width: '100%' }}>
      <Field
       type="text"
       name="marketing_source_explanation"
       placeholder={props.placeholder}
       autoComplete="off"
      />
     </div>
    </div>
   </React.Fragment>
  )
 }

 const handlePayment = async () => {
  const form = document.getElementById('formAuthorizeNetTestPage')
  if (form) form.submit()
 }

 // CHECK CONDITION AND DISPLAY DATA
 if (alertError === true) {
  return (
   <React.Fragment>
    <div className="registration">
     <div className="alertMessage">
      <div className="container">
       <div className="alert error">
        <div className="icon">
         <img
          src={require('../../assets/images/alert-icon/error.svg').default}
          alt=""
         />
        </div>
        <div className="text">
         <p
          dangerouslySetInnerHTML={{
           __html: responseMessage,
          }}
         ></p>
        </div>
       </div>
      </div>
     </div>
    </div>
   </React.Fragment>
  )
 } else {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>Enroll</title>{' '}
    </Helmet>
    <Formik
     initialValues={initialValues}
     validationSchema={validationSchema}
     onSubmit={async (values, { resetForm }) => {
      await onSubmit(values)
     }}
    >
     {({ errors, touched, isValidating }) => (
      <div className="sectionWrap">
       <div className="sectionContainer">
        <Form>
         <div className="registration">
          <div className="pageTitle">
           <h4>
            Enroll Page - Basic Plan{' '}
            <small>Bennabis Health Membership Account</small>
           </h4>
           <small>
            After enrolling, a request to verify your account with instructions
            on accessing your Membership will be emailed to you. Please add{' '}
            <u className="hyperlink">no-reply@bennabishealth.com</u> to your
            contacts to ensure that you receive this email.
           </small>
          </div>
          <div className="dataForm">
           <div className="data_row">
            <div className="inputField">
             <label htmlFor="title">First Name *</label>
             <Field type="text" name="firstName" autoComplete="off" />
             <ErrorMessage name="firstName" render={renderError} />
            </div>
            <div className="inputField">
             <label htmlFor="title">Last Name *</label>
             <Field type="text" name="lastName" autoComplete="off" />
             <ErrorMessage name="lastName" render={renderError} />
            </div>
           </div>
           <div className="data_row">
            <div className="inputField">
             <label htmlFor="title">Date of Birth *</label>
             <DatePickerField name="dob" />
             <ErrorMessage name="dob" render={renderError} />
            </div>
            <div className="inputField">
             <label htmlFor="title">Mobile</label>
             <Field type="text" name="mobile" autoComplete="off" />
             <ErrorMessage name="mobile" render={renderError} />
            </div>
           </div>

           <div className="data_row">
            <div className="inputField">
             <label htmlFor="title">Address 1 *</label>
             <Field type="text" name="address_01" autoComplete="off" />
             <ErrorMessage name="address_01" render={renderError} />
            </div>
            <div className="inputField">
             <label htmlFor="title">Address 2</label>
             <Field type="text" name="address_02" autoComplete="off" />
             <ErrorMessage name="address_02" render={renderError} />
            </div>
           </div>
           <div className="data_row">
            <div className="inputField">
             <label htmlFor="title">City *</label>
             <Field type="text" name="city" autoComplete="off" />
             <ErrorMessage name="city" render={renderError} />
            </div>
            <div className="inputField">
             <label htmlFor="title">State *</label>
             <Field type="text" name="state" autoComplete="off" />
             <ErrorMessage name="state" render={renderError} />
            </div>
            <div className="inputField">
             <label htmlFor="title">Zip *</label>
             <Field type="text" name="zip" autoComplete="off" />
             <ErrorMessage name="zip" render={renderError} />
            </div>
           </div>
           <div className="data_row">
            <div className="inputField">
             <label htmlFor="title">How Did You Hear About Us ? *</label>
             <div className="selectbox">
              <select
               name="marketing"
               onChange={(e) => setMarketingsource(e.target.value)}
              >
               <option value="10">Referred By</option>
               <option value="2">
                Social Media (Instagram/Facebook/LinkedIn)
               </option>
               <option value="3">Internet Radio</option>
               <option value="4">NJ 101.5 Radio</option>

               <option value="5">Word of Mouth/Friend</option>
               <option value="6">Non-Profit Group</option>
               <option value="7">Dispensary</option>
               <option value="8">Health Care Provider </option>
               <option value="1">Prefer Not to Say</option>
               <option value="9">Other</option>
              </select>
             </div>
             {marketingsource === '5' && (
              <CollectSource placeholder="What is the name of friend?" />
             )}
             {marketingsource === '6' && (
              <CollectSource placeholder="What is the name of the Non-Profit Group?" />
             )}
             {marketingsource === '7' && (
              <CollectSource placeholder="What is the name of the Dispensary?" />
             )}
             {marketingsource === '8' && (
              <CollectSource placeholder="What is the name of the Healthcare Provider?" />
             )}
             {marketingsource === '9' && (
              <CollectSource placeholder="Please describe how you heard about us." />
             )}
             {marketingsource === '10' && (
              <CollectSource placeholder="Please Input Member Name / Member Id" />
             )}
            </div>
            <div className="inputField">
             <div style={{ display: 'flex', columnGap: '5px' }}>
              <label htmlFor="title">Promo/Referral Code</label>
              {!!promocodeText.length && discount !== null && (
               <div
                style={{
                 width: '20px',
                 height: '20px',
                }}
               >
                {discount.discount > 0 ? (
                 <IconSuccess
                  style={{
                   width: '100%',
                   height: '100%',
                  }}
                 />
                ) : (
                 <IconError
                  style={{
                   width: '100%',
                   height: '100%',
                  }}
                 />
                )}
               </div>
              )}
             </div>
             <Field
              type="text"
              name="promoCode"
              autoComplete="off"
              onBlur={() => {
               if (!!promocodeText) getPromocode()
              }}
              onChange={(e) => {
               setPromocodeText(e.target.value)
               setDiscount(null)
              }}
              value={promocodeText}
             />
             {!!promocodeText.length && discount !== null && (
              <div
               style={{
                height: '20px',
               }}
              >
               {discount.discount > 0 ? (
                <p
                 style={{
                  fontSize: '12px',
                  color: '#339900',
                 }}
                >
                 Discount awarded
                </p>
               ) : (
                <p style={{ color: '#CC3300', fontSize: '12px' }}>
                 Invalid promocode
                </p>
               )}
              </div>
             )}
            </div>
           </div>
           <div className="data_row">
            <div className="inputField">
             <label htmlFor="title">Email *</label>
             <Field type="email" name="email" autoComplete="off" />
             <ErrorMessage name="email" render={renderError} />
            </div>
            <div className="inputField">
             <label htmlFor="title">Password *</label>
             <Field type="password" name="password" autoComplete="off" />
             <ErrorMessage name="password" render={renderError} />
            </div>
            <div className="inputField">
             <label htmlFor="title">Password Confirmation *</label>
             <Field
              type="password"
              name="password_confirmation"
              autoComplete="off"
             />
             <ErrorMessage name="password_confirmation" render={renderError} />
            </div>
           </div>
           <div className="pageTitle">
            <small>- Password must be at least 8 characters in length.</small>
            <small>
             - Password must include at least one upper case letter.
            </small>
            <small>- Password must include at least one number.</small>
            <small>
             - Password must include at least one special character.
            </small>
           </div>
          </div>
          <div className="authorization">
           <h6>Bennabis Health Membership Account</h6>
           <p>
            By enrolling as a Bennabis Health Member, you agree to Bennabis
            Health's{' '}
            <Link
             to="/policies/terms-of-use"
             target="_blank"
             rel="noopener noreferrer"
            >
             Terms of Use
            </Link>
            ,{' '}
            <Link
             to="/policies/medical-disclaimer"
             target="_blank"
             rel="noopener noreferrer"
            >
             Medical Disclaimer
            </Link>{' '}
            and{' '}
            <Link
             to="/policies/privacy-policy"
             target="_blank"
             rel="noopener noreferrer"
            >
             Privacy Policy.
            </Link>
           </p>
           <div className="police_accept">
            <ul>
             <li>
              <Field type="checkbox" name="acceptance_01" /> I accept the online
              account Terms of Use.{' '}
              <small>
               <ErrorMessage name="acceptance_01" render={renderError} />
              </small>{' '}
             </li>
            </ul>
           </div>
          </div>
          <div className="instruction">
           <ul>
            <li>
             After clicking Pay and Enroll, you will be directed to our payment
             portal to complete your enrollment.
            </li>
            <li>
             <strong>
              Please make a payment to complete your enrollment, check your
              email
             </strong>{' '}
             and follow the instructions to verify and access your Bennabis
             Health account.
            </li>
            <li>
             <strong>Have a promo/referral code?</strong> If you entered a
             Promo/Referral Code for a discounted membership, please click the
             “Payment Preview” button below to verify that your discount has
             been applied prior to making a payment.
            </li>
            <li>
             If you obtained your referral code from a nonprofit organization,
             please click{' '}
             <Link to="/policies/terms-of-use" target="_blank">
              here
             </Link>{' '}
             for legal disclosures.{' '}
            </li>
           </ul>
          </div>
         </div>
         <div className="dataForm">
          <div className="data_row">
           <button
            type="button"
            style={{
             display: 'block',
             fontFamily: 'inherit',
             fontSize: ' 0.875rem',
             lineHeight: 'inherit',
             border: ' 1px solid #dddcdb',
             borderRadius: '0.25rem',
             transition: 'all ease 0.4s',
             appearance: 'none',
             background: '#0A670A',
             color: '#fff',
             position: 'relative',
             padding: '0.5rem 0.75rem',
             paddingLeft: '2.25rem',
             cursor: 'pointer',
            }}
            disabled={isLoading}
            onClick={handleOpen}
           >
            <img
             style={{
              width: '22px',
              position: 'absolute',
              left: '0.5rem',
              top: '50%',
              transform: 'translateY(-50%)',
             }}
             src={require('../../assets/images/registration.svg').default}
             alt=""
            />{' '}
            Payment Preview
           </button>
           <SummaryDialog
            open={open}
            handleClose={handleClose}
            discount={discount}
            namePlan="Basic"
            promocode={promocodeText}
            membershipCost="29"
           />
           <button type="submit" disabled={isLoading}>
            <img
             src={require('../../assets/images/registration.svg').default}
             alt=""
            />
            Pay & Enroll
           </button>
           {isLoading && (
            <span>
             <CircularProgress
              style={{
               color: 'inherit',
              }}
              size="20px"
             />
            </span>
           )}
          </div>
          {errorForm && <p style={{ color: 'red' }}>{errorForm}</p>}
          <PaymentCards />
         </div>
        </Form>
       </div>
      </div>
     )}
    </Formik>
    <form
     method="post"
     action={process.env.REACT_APP_AUTHORIZE_PROD}
     id="formAuthorizeNetTestPage"
     name="formAuthorizeNetTestPage"
    >
     <input type="hidden" name="token" value={token} />
    </form>
   </React.Fragment>
  )
 }
}

export default BasicEnrollment
