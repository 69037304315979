import React from 'react'
import NavBar from './NavBar'

const Navigation = () => {
 return (
  <React.Fragment>
   <div className="navigationWrap" id="navigation">
    <div className="navigation">
     <NavBar />
    </div>
   </div>
  </React.Fragment>
 )
}

export default Navigation
