import React from 'react'
import {
 Box,
 Dialog,
 DialogActions,
 DialogContent,
 DialogTitle,
 Stack,
 Typography,
} from '@mui/material'

const SummaryDialog = ({
 open,
 handleClose,
 discount,
 namePlan,
 promocode,
 membershipCost,
}) => {
 return (
  <Dialog
   open={open}
   onClose={handleClose}
   sx={{
    '& .MuiDialog-container': {
     '& .MuiPaper-root': {
      width: '100%',
      maxWidth: '500px',
     },
    },
   }}
  >
   <DialogTitle>Summary Payment</DialogTitle>
   <DialogContent>
    <Stack rowGap="10px">
     <Box display="flex" justifyContent="space-between">
      <Typography>{namePlan} Membership</Typography>
      <Typography>${Number(membershipCost).toFixed(2)}</Typography>
     </Box>
     <Box display="flex" justifyContent="space-between">
      <Typography>Promo/Refferal code</Typography>
      <Typography>
       {!!promocode.length ? `- $${discount?.discount.toFixed(2)}` : '$0.00'}
      </Typography>
     </Box>
     <Box display="flex" justifyContent="space-between">
      <Typography fontWeight="700">Total</Typography>
      <Typography fontWeight="700">
       {!!promocode.length
        ? `$${Number(discount?.total).toFixed(2)}`
        : `$${Number(membershipCost).toFixed(2)}`}
      </Typography>
     </Box>
    </Stack>
   </DialogContent>
   <DialogActions>
    <button
     style={{
      display: 'block',
      fontFamily: 'inherit',
      fontSize: ' 0.875rem',
      lineHeight: 'inherit',
      border: ' 1px solid #dddcdb',
      borderRadius: '0.25rem',
      transition: 'all ease 0.4s',
      appearance: 'none',
      background: '#0A670A',
      color: '#fff',
      position: 'relative',
      padding: '0.5rem 0.75rem',
      cursor: 'pointer',
     }}
     onClick={handleClose}
    >
     Got it
    </button>
   </DialogActions>
  </Dialog>
 )
}
export default SummaryDialog
