import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const getYear = new Date().getFullYear();
  return (
    <React.Fragment>
      <div className="footerWrap" id="footer">
        <div className="footer">
          <div className="explore_links">
            <div className="supported">
              <div className="item"><img src={require("../assets/images/njcba.svg").default} alt="" /></div>
              <div className="item"><img src={require("../assets/images/njbia.svg").default} alt="" /></div>
              <div className="item"><img src={require("../assets/images/ncia.svg").default} alt="" /></div>
            </div>
            <div className="sitemap"> 
              <div className="nav_item">
                <h5>Company</h5>
                <ul>
                  <li><Link to='/about-us'>About Us</Link></li>
                  <li><Link to='/contact'>Contact Us</Link></li>
                  <li><Link to='/policies/cookie-policy'>Cookie Policy</Link></li>
                  <li><Link to='/policies/medical-disclaimer'>Medical Disclaimer</Link></li>
                  <li><Link to='/policies/privacy-policy'>Privacy Policy</Link></li>
                  <li><Link to='/policies/terms-of-use'>Terms of Use</Link></li>
                  <li><Link to='/faqs'>FAQs</Link></li>
                </ul>
              </div>
              <div className="nav_item">
                <h5>News & Updates</h5>
                <ul>
                  <li><Link to='/media/bennabis-health-news'>Bennabis Health News</Link></li>
                  {/*<li><Link to='/media/industry-news'>Industry News</Link></li>*/}
                  <li><Link to='/podcasts'>Podcasts</Link></li>
                  <li><Link to='/work-with-us/webinars'>Webinars</Link></li>
                  <li><Link to='/blog'>Blog</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="explore_media">
            <Link to='/press-releases'><i className="fa fa-file-text-o" aria-hidden="true"></i> PRESS RELEASES</Link>
            <Link to='/enroll'><i className="fa fa-user-circle-o" aria-hidden="true"></i> BECOME A MEMBER</Link>
            <Link to='/newsletter'><i className="fa fa-envelope-o" aria-hidden="true"></i> SIGN UP FOR BENNABIS HEALTH E-MAIL UPDATES</Link>
            <img src={require("../assets/images/footer-leaf.svg").default} alt="" />
          </div>
          <div className="quick_contact">
            <div className="item">&copy; {getYear} Bennabis Health, Inc.</div>
            <div className="item"><a rel="noreferrer" href='mailto:team@bennabishealth.com'>team@bennabishealth.com</a></div>
            <div className="item social_profile">
              <ul>
                <li><a rel="noreferrer" href='https://www.facebook.com/BennabisHealth' target='_blank'><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a rel="noreferrer" href='https://www.instagram.com/accounts/login/?next=/bennabishealth/' target='_blank'><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                <li><a rel="noreferrer" href='https://www.linkedin.com/company/bennabis-health' target='_blank'><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer;