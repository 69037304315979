import React, { useEffect, useState } from 'react'
import { debounce } from '@mui/material'
import NetworkDispensaryList from './NetworkDispensaryList'
import SecondaryHeader from '../../masterLayout/SecondaryHeader'
import { Helmet } from 'react-helmet'
import NetworkDispensaryMap from './NetworkDispensaryMap'
import NetworkDispensaryMapList from './NetworkDispensaryMapList'

const NetworkDispensary = () => {
 const [checkSize, setCheckSize] = useState(0)
 const [dispensaryState, setDispensaryState] = useState({})
 const [isLoading, setIsLoading] = useState(true)
 const [error, setError] = useState()
 const getData = async () => {
  try {
   const response = await fetch(`${process.env.REACT_APP_API_URL}/dispensaries`)
   const data = await response.json()
   setDispensaryState(data)
   setIsLoading(false)
  } catch (err) {
   setError(err.message)
  }
 }

 useEffect(() => {
  getData()
 }, [])

 const updateScreenSize = debounce(() => {
  setCheckSize(window.innerWidth)
 }, 50)

 useEffect(() => {
  updateScreenSize()
  window.addEventListener('resize', updateScreenSize)

  return () => {
   window.removeEventListener('resize', updateScreenSize)
  }
 }, [])

 return (
  <>
   <Helmet>
    <title>Network Dispensaries</title>
   </Helmet>
   <SecondaryHeader page_title="Network Dispensaries" />
   {error && <p style={{ color: '#FF0000' }}>Something went wrong</p>}
   {isLoading ? (
    <div className="showLoading">
     <img src={require('../../assets/images/dots.gif')} alt="" />
    </div>
   ) : checkSize < 800 ? (
    <div className="sectionWrap">
     <div className="sectionContainer" id="cannabis-education">
      <p style={{ marginBottom: '0' }}>
       * Recreational/Adult-Use dispensary supporting medical patients,
       discounts may vary, and taxes apply.
      </p>
      <div className="topic_list">
       <NetworkDispensaryList listDispensary={dispensaryState} />
      </div>
     </div>
    </div>
   ) : (
    <div style={{ marginBottom: '100px' }}>
     <NetworkDispensaryMap listDispensary={dispensaryState} />
     <NetworkDispensaryMapList listDispensary={dispensaryState} />
    </div>
   )}
  </>
 )
}
export default NetworkDispensary
