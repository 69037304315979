import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import SecondaryHeader from '../../masterLayout/SecondaryHeader';

const ConditionSpecific = () => { 
  const [topicCategory, settopicCategory] = useState([]); 

  // GET REQUEST FOR TOPIC CATEGORY
  const category = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/categories?category_type_id=10&parent_id=28&status_id=1`);
      settopicCategory(await response.json());
    } catch (e) {
      console.log(e)
    }
  }

  // USE EFFECT
  useEffect(() => { 
    category();
  }, []);

  return (
    <React.Fragment>
      <Helmet> <title>Condition Specific</title> </Helmet>
      <SecondaryHeader page_title='condition-specific' />
      <div className="sectionWrap">
        <div className="sectionContainer" id='cannabis-education'>
        <p><em>Disclaimer: The views, thoughts and opinions expressed in these articles are not intended to endorse or advocate for any particular course of treatment and may be based on anecdotal information. While you may find them helpful, they are not a substitute for the expertise, knowledge and judgement of healthcare professionals. They are personal opinions. For condition specific questions please talk to your doctor. Please see our <Link to="policies/medical-disclaimer" className='hyperlink'>Medical Disclaimer</Link>  for further information.</em></p>
          <div className='topic_list'>
            <ul>
              {topicCategory?.data?.sort((a,b) => a.category.toLowerCase().localeCompare(b.category.toLowerCase())).map((data) => {
                return (
                  <li key={data.id}><Link to={`/education/condition-specific/${data.id}`}>{data.category}</Link></li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ConditionSpecific