import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import SecondaryHeader from '../masterLayout/SecondaryHeader'

const PressReleases = () => {
 const [error, setError] = useState(null)
 const [isLoaded, setIsLoaded] = useState(false)
 const [Post, setPost] = useState([])

 const getData = async () => {
  try {
   const response = await fetch(
    `${process.env.REACT_APP_API_URL}/categories?slug=press-releases`,
   )
   const data = await response.json()
   const responseCat = await fetch(
    `${process.env.REACT_APP_API_URL}/media?category_id=${data.data[0].id}&status_id=1`,
   )
   setPost(await responseCat.json())
   setIsLoaded(true)
  } catch (error) {
   setError(error)
  }
 }

 useEffect(() => {
  getData()
 }, [])

 if (error) {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>Press Releases</title>{' '}
    </Helmet>
    <SecondaryHeader page_title="Press Releases" />
    <div className="showError">
     <span>Error Message: {error.message}</span>
    </div>
   </React.Fragment>
  )
 } else if (!isLoaded) {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>Press Releases</title>{' '}
    </Helmet>
    <SecondaryHeader page_title="Press Releases" />
    <div className="showLoading">
     <img src={require('../assets/images/dots.gif')} alt="" />
    </div>
   </React.Fragment>
  )
 } else {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>Press Releases</title>{' '}
    </Helmet>
    <SecondaryHeader page_title="Press Releases" />
    <div className="sectionWrap">
     <div className="sectionContainer">
      <div className="gridPost">
       {Post?.data?.map((val) => {
        return (
         <div className="item" key={val.id}>
          <Link to={`/press-releases/${val.id}`} style={{ height: '100%' }}>
           {val.thumbnail !== null ? (
            <div className="thumbnail">
             <img width="100%" src={val.thumbnail} alt="" />
            </div>
           ) : (
            <div style={{ height: '100%' }}></div>
           )}
           <div className="title">
            <p>{val.title}</p>
           </div>
          </Link>
         </div>
        )
       })}
      </div>
     </div>
    </div>
   </React.Fragment>
  )
 }
}
export default PressReleases
