import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import SecondaryHeader from '../masterLayout/SecondaryHeader'
import {
 Dialog,
 DialogActions,
 DialogContent,
 DialogTitle,
 Stack,
} from '@mui/material'

const Media = () => {
 const [error, setError] = useState(null)
 const [isLoaded, setIsLoaded] = useState(false)
 const { slug } = useParams()
 const [open, setOpen] = useState(false)
 const [Post, setPost] = useState([])
 const [selectedOption, setSelectedOption] = useState(null)
 const [dataInactive, setDataInactive] = useState(null)

 const handleOpen = (val) => {
  setSelectedOption(val)
  setOpen(true)
 }
 const handleClose = () => setOpen(false)

 // CONVERT STRING TO TITLE CASE
 function titleCase(str) {
  return str
   .toLowerCase()
   .split(' ')
   .map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1)
   })
   .join(' ')
 }

 // GET REQUEST FOR MEDIA LIST
 const getCategories = async () => {
  try {
   const response = await fetch(
    `${process.env.REACT_APP_API_URL}/categories?slug=${slug}`,
   )
   const data = await response.json()
   setDataInactive(data)
   setIsLoaded(true)
   if (data.data[0].status_id === 3) return
   const responseCat = await fetch(
    `${process.env.REACT_APP_API_URL}/media?category_id=${data.data[0].id}&status_id=1`,
   )
   setPost(await responseCat.json())
   setIsLoaded(true)
  } catch (error) {
   setError(error)
  }
 }

 useEffect(() => {
  getCategories()
 }, [slug])

 if (error) {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>{titleCase(slug.replace(/-/g, ' '))}</title>{' '}
    </Helmet>
    <SecondaryHeader page_title={slug} />
    <div className="showError">
     <span>Error Message: {error.message}</span>
    </div>
   </React.Fragment>
  )
 } else if (!isLoaded) {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>{titleCase(slug.replace(/-/g, ' '))}</title>{' '}
    </Helmet>
    <SecondaryHeader page_title={slug} />
    <div className="showLoading">
     <img src={require('../assets/images/dots.gif')} alt="" />
    </div>
   </React.Fragment>
  )
 } else {
  return (
   <React.Fragment>
    <Helmet>
     {' '}
     <title>{titleCase(slug.replace(/-/g, ' '))}</title>{' '}
    </Helmet>
    <SecondaryHeader page_title={slug} />
    <div className="sectionWrap">
     <div className="sectionContainer">
      {dataInactive?.data[0]?.status_id === 3 && (
       <div className="sectionWrap">
        <p
         style={{
          paddingTop: '40px',
          fontSize: '20px',
          textAlign: 'center',
          fontWeight: 500,
         }}
        >
         Content temporary unavailable
        </p>
       </div>
      )}
      <div className="gridPost">
       {Post?.data?.map((val) => {
        return (
         <div className="item" key={val.id} style={{ height: '100%' }}>
          {val.thumbnail !== null ? (
           <div className="thumbnail">
            <img width="100%" src={val.thumbnail} alt="" />
           </div>
          ) : (
           <div style={{ height: '250px' }}></div>
          )}
          <div style={{ padding: '0.75rem' }}>
           <p>{val.title}</p>
           {!!val.description.length && (
            <p
             onClick={() => handleOpen(val)}
             style={{
              cursor: 'pointer',
              display: 'inline-block',
              color: '#082F58',
             }}
            >
             Read more
            </p>
           )}
           <a
            rel="noreferrer"
            href={val.source}
            target="_blank"
            style={{ width: 'max-content' }}
           >
            <p style={{ margin: 0 }}>Source</p>
           </a>
          </div>
         </div>
        )
       })}
       <Dialog
        open={open}
        onClose={handleClose}
        sx={{
         '& .MuiDialog-container': {
          '& .MuiPaper-root': {
           width: '100%',
           maxWidth: '500px',
          },
         },
        }}
       >
        <DialogTitle>{selectedOption?.title}</DialogTitle>
        <DialogContent>
         <Stack rowGap="10px">
          <div
           className="description ql-editor"
           dangerouslySetInnerHTML={{ __html: selectedOption?.description }}
          ></div>
         </Stack>
        </DialogContent>
        <DialogActions>
         <button
          style={{
           display: 'block',
           fontFamily: 'inherit',
           fontSize: ' 0.875rem',
           lineHeight: 'inherit',
           border: ' 1px solid #dddcdb',
           borderRadius: '0.25rem',
           transition: 'all ease 0.4s',
           appearance: 'none',
           background: '#0A670A',
           color: '#fff',
           position: 'relative',
           padding: '0.5rem 0.75rem',
           cursor: 'pointer',
          }}
          onClick={handleClose}
         >
          Got it
         </button>
        </DialogActions>
       </Dialog>
      </div>
     </div>
    </div>
   </React.Fragment>
  )
 }
}

export default Media
